import { callApi } from "./api";

export const apiLoginWithGoogle = (data) => {
   return callApi({
      url: "/api/v1/user/login_with_google",
      method: "POST",
      data,
   });
};

export const apiSignUp = (data) => {
   return callApi({
      url: "/api/v1/users",
      method: "POST",
      data,
   });
};

export const apiSignIn = (data) => {
   return callApi({
      url: "/api/v1/user/sessions",
      method: "POST",
      data,
   });
};

export const apiRefreshData = (data) => {
   return callApi({
      url: "/api/v1/users/show",
      method: "POST",
      data,
   });
};
