import "./theme.css";
import { GlobalStyles, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <BrowserRouter>
         <ThemeProvider theme={theme}>
            <GlobalStyles
               styles={{
                  "*, *::before, *::after": {
                     margin: 0,
                     boxSizing: "border-box",
                     fontFamily: "Open Sans, sans-serif",
                  },
                  body: {
                     backgroundColor: "#f8f8f8",
                  },
               }}
            />
            <App />
         </ThemeProvider>
      </BrowserRouter>
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
