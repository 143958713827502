import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export const Home = () => {
   const user = useSelector((state) => state.user);

   if (!user) {
      window.location.href = "/auth/login";
   } else {
      window.location.href = "/dashboard";
   }

   return <Box />;
};
