import {
   AttachMoney,
   ContentCopy,
   ExitToApp,
   Pending,
   Wifi,
} from "@mui/icons-material";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

import { getEthValue, parseValueToEth } from "../../utils";

import { useStyles } from "./styles";

const VimCardViewData1 = ({ data, currentEthValue }) => {
   const classes = useStyles();

   const statusIcon = (status) => {
      if (status == "Active Online") {
         return <Wifi />;
      }

      if (status == "Pending") {
         return <Pending />;
      }

      if (status == "Deposited") {
         return <AttachMoney />;
      }

      return <ExitToApp />;
   };

   const statusColor = (status) => {
      if (status == "Active Online") {
         return "#00D0B5";
      }

      return "#3A3F63";
   };

   return (
      <Grid item container spacing={2} className={classes.vim_crypto_card}>
         {data.map((item, index) => {
            return (
               <>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                     <Card className={classes.card_data1}>
                        <CardContent>
                           <Grid sx={{ display: "flex" }}>
                              <Grid
                                 item
                                 xs={12}
                                 sm={12}
                                 md={4}
                                 lg={4}
                                 key={index}
                                 sx={{
                                    p: "0 10px",
                                    borderRight: "1px solid #d8d8d8",
                                 }}
                              >
                                 <Grid
                                    sx={{
                                       display: "flex",
                                       color: "#3A3F63",
                                       alignItems: "center",
                                       justifyContent: "space-between",
                                    }}
                                 >
                                    <Typography
                                       sx={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                       }}
                                    >
                                       {item.name || "NAME"}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          fontSize: "14px",
                                          pl: "1rem",
                                          textDecoration: "underline",
                                       }}
                                    >
                                       <a
                                          href={`https://beaconcha.in/validator/${item.validator_index}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                       >
                                          {item.validator_index}
                                       </a>
                                    </Typography>
                                 </Grid>
                                 <Grid
                                    sx={{
                                       display: "flex",
                                       color: "#3A3F63",
                                       alignItems: "center",
                                       justifyContent: "space-between",
                                    }}
                                 >
                                    <Typography
                                       sx={{
                                          fontSize: "14px",
                                       }}
                                    >
                                       {`${item.validator_wallet_address?.substring(
                                          0,
                                          7
                                       )}...`}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          fontSize: "12px",
                                          pl: "1rem",
                                          textDecoration: "underline",
                                          textAlign: "right",
                                       }}
                                    >
                                       <ContentCopy
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                             navigator.clipboard
                                                .writeText(
                                                   item.validator_wallet_address
                                                )
                                                .then(() =>
                                                   alert(
                                                      "Wallet address copied"
                                                   )
                                                )
                                          }
                                       />
                                    </Typography>
                                 </Grid>
                                 <Grid
                                    sx={{
                                       display: "flex",
                                       width: "100%",
                                       justifyContent: "space-between",
                                       alignItems: "center",
                                       color: "#3A3F63",
                                    }}
                                 >
                                    <Typography sx={{ fontSize: "14px" }}>
                                       ETH
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                       {parseValueToEth(item.balance, 9)}
                                    </Typography>
                                 </Grid>
                                 <Grid
                                    sx={{
                                       display: "flex",
                                       width: "100%",
                                       justifyContent: "space-between",
                                       alignItems: "center",
                                       color: "#3A3F63",
                                    }}
                                 >
                                    <Typography sx={{ fontSize: "14px" }}>
                                       USD
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                       {getEthValue(
                                          item.balance,
                                          currentEthValue,
                                          9
                                       ).toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                       })}
                                    </Typography>
                                 </Grid>
                                 {item.status && (
                                    <Grid
                                       sx={{
                                          display: "flex",
                                          color: statusColor(item.status),
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                       }}
                                    >
                                       <Typography
                                          sx={{
                                             fontSize: "12px",
                                          }}
                                       >
                                          {statusIcon(item.status)}
                                       </Typography>
                                       <Typography
                                          sx={{
                                             fontSize: "12px",
                                             color: "#3A3F63",
                                             pl: "1rem",
                                             textAlign: "right",
                                          }}
                                       >
                                          {item.status}
                                       </Typography>
                                    </Grid>
                                 )}
                              </Grid>
                              <Grid
                                 item
                                 xs={12}
                                 sm={12}
                                 md={4}
                                 lg={4}
                                 key={index}
                                 sx={{
                                    p: "0 10px",
                                    borderRight: "1px solid #d8d8d8",
                                 }}
                              >
                                 <Grid
                                    sx={{
                                       height: "100%",
                                       display: "flex",
                                       flexDirection: "column",
                                       alignItems: "center",
                                       justifyContent: "center",
                                       p: 0,
                                       m: 0,
                                       color: "#3A3F63",
                                    }}
                                 >
                                    <Typography
                                       variant="h5"
                                       sx={{
                                          color: "#00D0B5",
                                          fontWeight: "bold",
                                          m: 0,
                                       }}
                                    >
                                       {item.performance365d}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          m: 0,
                                          fontStyle: "italic",
                                          textAlign: "center",
                                       }}
                                    >
                                       Income (ETH)
                                    </Typography>
                                 </Grid>
                              </Grid>
                              <Grid
                                 item
                                 xs={12}
                                 sm={12}
                                 md={4}
                                 lg={4}
                                 key={index}
                                 sx={{
                                    p: "0 10px",
                                 }}
                              >
                                 <Grid
                                    sx={{
                                       height: "100%",
                                       display: "flex",
                                       flexDirection: "column",
                                       alignItems: "center",
                                       justifyContent: "center",
                                       p: 0,
                                       m: 0,
                                       color: "#3A3F63",
                                    }}
                                 >
                                    <Typography
                                       variant="h5"
                                       sx={{
                                          fontWeight: "bold",
                                          m: 0,
                                       }}
                                    >
                                       {item.apr_365d}
                                    </Typography>
                                    <Typography
                                       sx={{
                                          m: 0,
                                          fontStyle: "italic",
                                       }}
                                    >
                                       APR (ETH)
                                    </Typography>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </CardContent>
                     </Card>
                  </Grid>
               </>
            );
         })}
      </Grid>
   );
};

export default VimCardViewData1;
