import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
   layout_container: {
      display: "flex",
   },
   main_layout: {
      height: "calc(100vh - 0rem)",
      width: "calc(100% - 4rem)",
      overflowY: "auto",
      background: "#e8ecf8",
      padding: "84px 0 112px 0",

      [theme.breakpoints.down("sm")]: {
         width: "100%",
         height: "calc(100vh - 78px)",
      },
   },
   main_layout_close: {
      width: "calc(100% - 4rem)",
   },
   dashboard_content: {
      padding: "28px 58px 25px 32px",

      [theme.breakpoints.down("sm")]: {
         padding: "20px 16px",
      },
   },
}));
