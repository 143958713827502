import { Box, Stack } from "@mui/material";
import React from "react";
import { useRef } from "react";

import MobileMenus from "../DashboardExapand/MobileMenus";
import DashboardSidebar from "../DashboardSidebar";
import Header from "../Header/index";

import { useStyles } from "./styles";

const DashboardLayout = ({ children }) => {
   const classes = useStyles();
   const layoutRef = useRef();
   const [status, setStatus] = React.useState(false);

   const toggle = () => {
      setStatus(!status);
   };

   return (
      <>
         <Header toggle={toggle} />
         <div className={classes.layout_container}>
            <DashboardSidebar
               handleDrawerToggle={toggle}
               setStatus={setStatus}
               status={status}
            />
            <Box
               className={[
                  classes.main_layout,
                  status && classes.main_layout_close,
               ].join(" ")}
               id="mainLayout"
               ref={layoutRef}
               component="main"
            >
               <Stack className={classes.dashboard_content}>{children}</Stack>
            </Box>
         </div>

         <Box sx={{ display: { sm: "none", sx: "block" } }}>
            <MobileMenus />
         </Box>
      </>
   );
};

export default DashboardLayout;
