import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
   mobile_menus: {
      background: "#fff",
      width: "100%",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
   },
   tabs: {
    width: "100%",
   },
   tab_item: {
    fontSize: "14px",
    textTransform: "capitalize",
    padding: "15px 15px",
   },
}));
