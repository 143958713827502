import BaseAxios from "axios";

const axios = BaseAxios.create({ timeout: 60000 });

export const callApi = async (apiConfig) => {
  try {
    const headers = {
      Accept: "application/json",
    };
    const response = await axios.request({
      baseURL: process.env.REACT_APP_API,
      headers,
      ...apiConfig,
    });
    return response.data;
  } catch (error) {
    // Deal with errors here
    throw error;
  }
};
