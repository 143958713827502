import {
   Box,
   Button,
   Grid,
   Modal,
   TextField,
   Typography,
} from "@mui/material";
import React, { useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";

import Delete_Icon from "../../assets/images/Delete_Icon.svg";

import { useStyles } from "./styles";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 450,
   bgcolor: "background.paper",
   boxShadow: 24,
   p: "20px 40px 20px 20px",
   borderRadius: "20px",
};

const PopupEditNode = ({
   modalOpen,
   onClose,
   onOpen,
   title,
   newTitle,
   onNewTitle,
   nodes,
   nameUpdates,
   onUpdateName,
   onDeleteNode,
   onDeleteList,
   onUpdateList,
}) => {
   const classes = useStyles();
   const [error, setError] = useState();
   const [requesting, setRequesting] = useState();

   return (
      <Modal
         open={modalOpen}
         onClose={onClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
      >
         <Box sx={style}>
            <Grid>
               <IoIosArrowRoundBack
                  className={classes.arrow_icon}
                  style={{ cursor: "pointer" }}
                  size={27}
                  onClick={() => onOpen()}
               />
            </Grid>
            <Grid className={classes.text_title}>
               <Typography
                  fontWeight={700}
                  textTransform={"uppercase"}
                  lineHeight={"40px"}
                  color={"rgba(58, 63, 99, 1)"}
               >
                  Edit list: {title ? title : "List"}
               </Typography>
            </Grid>
            <Grid className={classes.name_box_container}>
               <Typography
                  display="block"
                  sx={{ marginTop: 3, color: "rgba(58, 63, 99, 1)" }}
               >
                  Name
               </Typography>
               <TextField
                  fullWidth
                  name="fname"
                  placeholder="My List"
                  id="fullWidth"
                  value={newTitle}
                  onChange={(e) => onNewTitle(e.target.value)}
               />
               <Typography
                  display="block"
                  sx={{ marginTop: 3, color: "rgba(58, 63, 99, 1)" }}
               >
                  Nodes
               </Typography>
               <Grid spacing={2}>
                  {nodes.map((i) => {
                     return (
                        <Grid item sm={12} md={12} lg={12}>
                           <Grid
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-evenly",
                                 margin: "10px",
                              }}
                           >
                              <Typography className={classes.text_details}>
                                 {i.validator_index}
                              </Typography>
                              <TextField
                                 size="small"
                                 placeholder="Node name"
                                 value={nameUpdates[i.id]}
                                 name="name"
                                 fullWidth
                                 style={{ margin: "0 10px" }}
                                 onChange={(e) =>
                                    onUpdateName(i.id, e.target.value)
                                 }
                              />
                              <Grid
                                 className={classes.delete_icon_container}
                                 style={{ cursor: "pointer" }}
                                 onClick={() => onDeleteNode(i.id)}
                              >
                                 <img
                                    src={Delete_Icon}
                                    alt="deleteLogo"
                                    className={classes.delete_icon}
                                 />
                              </Grid>
                           </Grid>
                        </Grid>
                     );
                  })}
               </Grid>
               <Grid
                  sx={{
                     display: "flex",
                     justifyContent: "end",
                     alignItems: "center",
                     mt: "1rem",
                  }}
               >
                  <Button
                     sx={{
                        textTransform: "capitalize",
                        color: "red",
                        pr: "20px",
                     }}
                     disabled={requesting}
                     onClick={onDeleteList}
                  >
                     Delete list
                  </Button>
                  <Button
                     variant="contained"
                     size="small"
                     className={classes.add_btn}
                     onClick={onUpdateList}
                     disabled={requesting}
                  >
                     Save
                  </Button>
               </Grid>
               {error && (
                  <Box style={{ margin: "10px", color: "#ff0000" }}>
                     <Typography>{error}</Typography>
                  </Box>
               )}
            </Grid>
         </Box>
      </Modal>
   );
};

export default PopupEditNode;
