import { NoAdultContentOutlined, Refresh } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   CircularProgress,
   Dialog,
   DialogContent,
   Divider,
   FormControl,
   Grid,
   InputLabel,
   MenuItem,
   Select,
   TextField,
   Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { callApi } from "../../api/api";
import { apiRefreshData } from "../../api/user";
import down_icon from "../../assets/images/dashboard/down_icon.svg";
import menuImage from "../../assets/images/dashboard/menu.svg";
import up_icon from "../../assets/images/dashboard/upIcon.svg";
import VimCrypto from "../../components/DashboardExapand/VimCrypto";
import DashboardLayout from "../../components/DashboardLayout";
import Expandable from "../../components/Expandable";
import PopupEditNode from "../../components/PopupEditNode";
import VimCardViewData1 from "../../components/VimCardViewData1";
import { STYLING_PAGES, getEthValue, parseValueToEth } from "../../utils";

import { useStyles } from "./styles";

const Dashboard = () => {
   const dispatch = useDispatch();
   const user = useSelector((state) => state.user);

   if (!user) {
      window.location.href = "/auth/login";
   }

   const classes = useStyles();
   const [listName, setListName] = useState("");
   const [open, setOpen] = useState(STYLING_PAGES.VIM_CRYPTO);
   const [newNodeIndex, setNewNodeIndex] = useState();
   const [addingNode, setAddingNode] = useState();
   const [currentEthPrice, setCurrentEthPrice] = useState();
   const [currentEthMarketCap, setCurrentEthMarketCap] = useState();
   const [currentEthVolume, setCurrentEthVolume] = useState();
   const [dayChange, setDayChange] = useState();
   const [newListName, setNewListName] = useState();
   const [viewDetail, setViewDetail] = useState(false);
   const [nodeDetail, setNodeDetail] = useState(null);
   const [modalOpen, setModalOpen] = useState(false);
   const handleClose = () => setModalOpen(false);
   const [nameUpdates, setNameUpdates] = useState({});
   const [newTitle, setNewTitle] = useState(null);
   const [error, setError] = useState();
   const [requesting, setRequesting] = useState();
   const [refreshing, setRefreshing] = useState();

   const allNodeLists = Object.keys(user.all_nodes);

   useEffect(() => {
      if (!currentEthPrice) {
         fetch(
            "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true"
         )
            .then((response) => response.json())
            .then((data) => {
               const mktCap = data.ethereum.usd_market_cap;
               const formattedMktCap = mktCap.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
               });
               setCurrentEthMarketCap(formattedMktCap);

               const price = data.ethereum.usd;
               const formattedPrice = price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
               });
               setCurrentEthPrice(formattedPrice);

               const vol = data.ethereum.usd_24h_vol;
               const formattedVol = vol.toLocaleString("en-US");
               setCurrentEthVolume(formattedVol);

               setDayChange(data.ethereum.usd_24h_change.toFixed(1));
            });
      }
   }, [currentEthPrice]);

   const handleChange = (event) => {
      setListName(event.target.value);
   };

   const handleClick = (value) => {
      if (value === open) setOpen(null);
      else setOpen(value);
   };

   const associateNode = () => {
      setAddingNode(true);

      var params = {
         auth_token: user.auth_token,
         node: {
            user_id: user.id,
            validator_index: newNodeIndex,
         },
      };

      if (listName === "Add List +") {
         params.node_list = {
            name: newListName,
         };
      } else {
         if (allNodeLists.includes(listName)) {
            params.node.node_list_id = user.all_nodes[listName][0].node_list_id;
         } else {
            params.node_list = {
               name: listName,
            };
         }
      }

      callApi({
         url: "/api/v1/nodes/",
         method: "POST",
         data: params,
      })
         .then((res) => {
            setNewNodeIndex();
            dispatch({ type: "SET_USER", payload: res });
         })
         .finally(() => setAddingNode());
   };

   const handleViewDetail = (nodeName) => {
      let obj = {
         type: STYLING_PAGES.VIM_CRYPTO,
         title: nodeName,
         nodetitle: `${user.all_nodes[nodeName].length} NODES`,
         balance: parseValueToEth(user.aggregate_by_list[nodeName].balance, 9),
         usdBalance: getEthValue(
            user.aggregate_by_list[nodeName].balance,
            currentEthPrice,
            9
         ).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
         }),
         income: user.aggregate_by_list[nodeName].total_rewards,
         avg: user.aggregate_by_list[nodeName].apr,
         name: nodeName,
         currentEthValue: currentEthPrice,
         nodes: user.all_nodes[nodeName],
         nodeId: user.all_nodes[nodeName][0].node_list_id,
      };
      setNodeDetail(obj);
      setViewDetail(true);
   };

   const handleBack = () => {
      setViewDetail(false);
      setNodeDetail(null);
   };

   const handleModal = () => {
      setModalOpen(true);
   };

   const handleNameUpdate = (index, val) => {
      setNameUpdates({
         ...nameUpdates,
         [index]: val,
      });
   };

   const handleUpdateList = () => {
      if (nodeDetail) {
         setRequesting(true);

         const nodes_attributes = [];

         Object.keys(nameUpdates).forEach((id) => {
            nodes_attributes.push({ id, name: nameUpdates[id] });
         });

         callApi({
            url: `/api/v1/node_lists/${nodeDetail.nodeId}`,
            method: "PUT",
            data: {
               node_list: {
                  name: newTitle,
                  nodes_attributes,
               },
            },
         })
            .then((res) => {
               if (res.errors) {
                  setError("Error updating, try again.");
               } else {
                  dispatch({ type: "SET_USER", payload: res });
                  setModalOpen();
               }
            })
            .finally(() => setRequesting());
      }
   };

   const handleRefresh = () => {
      setRefreshing(true);

      const params = {
         auth_token: user.auth_token,
      };

      apiRefreshData(params)
         .then((res) => {
            dispatch({ type: "SET_USER", payload: res });
         })
         .finally(() => setRefreshing());
   };

   const handleDeleteList = () => {
      if (nodeDetail) {
         const userConfirmed = window.confirm(
            "Are you sure you want to delete this list?"
         );

         if (userConfirmed) {
            setRequesting(true);

            callApi({
               url: `/api/v1/node_lists/${nodeDetail.nodeId}`,
               method: "DELETE",
            })
               .then((res) => dispatch({ type: "SET_USER", payload: res }))
               .finally(() => setRequesting());
         }
      }
   };

   const handleDeleteNode = (index) => {
      const userConfirmed = window.confirm(
         "Are you sure you want to delete this node?"
      );

      if (userConfirmed) {
         setRequesting(true);

         callApi({
            url: `/api/v1/nodes/${index}`,
            method: "DELETE",
         })
            .then((res) => dispatch({ type: "SET_USER", payload: res }))
            .finally(() => setRequesting());
      }
   };

   return (
      <DashboardLayout>
         <Box className={classes.view_desktop}>
            <Grid item container>
               <Grid item xs={12} sm={12} md={3} lg={2}>
                  <Grid>
                     <Card className={classes.card_container}>
                        <CardContent>
                           <Typography
                              sx={{
                                 fontSize: 16,
                                 fontWeight: 900,
                                 color: "white",
                              }}
                              gutterBottom
                           >
                              ADD NODE
                           </Typography>
                           <FormControl
                              fullWidth
                              sx={{ mt: "6px" }}
                              size="small"
                           >
                              <InputLabel id="demo-simple-select-label">
                                 List
                              </InputLabel>
                              <Select
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 value={listName}
                                 label="List"
                                 onChange={handleChange}
                                 IconComponent={ArrowDropDownIcon}
                                 sx={{
                                    "& .MuiInputBase-input": {
                                       background: "white",
                                    },
                                 }}
                              >
                                 {allNodeLists.map((i) => {
                                    return <MenuItem value={i}>{i}</MenuItem>;
                                 })}
                                 <MenuItem value={"Add List +"}>
                                    Add List +
                                 </MenuItem>
                              </Select>
                           </FormControl>

                           {listName === "Add List +" && (
                              <>
                                 <Typography
                                    variant="body2"
                                    sx={{ mt: "10px", color: "white" }}
                                 >
                                    List Name
                                 </Typography>
                                 <Grid sx={{ mt: "6px" }}>
                                    <TextField
                                       InputProps={{
                                          style: {
                                             background: "white",
                                             height: "2rem",
                                          },
                                       }}
                                       placeholder="Ex. My List"
                                       fullWidth
                                       id="outlined-basic"
                                       variant="outlined"
                                       value={newListName}
                                       onChange={(e) =>
                                          setNewListName(e.target.value)
                                       }
                                    />
                                 </Grid>
                              </>
                           )}

                           <Typography
                              variant="body2"
                              sx={{ mt: "10px", color: "white" }}
                           >
                              Validator Index
                           </Typography>
                           <Grid sx={{ mt: "6px" }}>
                              <TextField
                                 InputProps={{
                                    style: {
                                       background: "white",
                                       height: "2rem",
                                    },
                                 }}
                                 placeholder="Ex. 123456"
                                 fullWidth
                                 id="outlined-basic"
                                 variant="outlined"
                                 value={newNodeIndex}
                                 onChange={(e) =>
                                    setNewNodeIndex(e.target.value)
                                 }
                              />
                           </Grid>
                        </CardContent>
                        <CardActions className={classes.add_btn_container}>
                           <Button
                              variant="contained"
                              size="small"
                              className={classes.add_btn}
                              disabled={!newNodeIndex || addingNode}
                              onClick={associateNode}
                           >
                              Add
                           </Button>
                        </CardActions>
                     </Card>
                  </Grid>

                  <Grid>
                     <Card className={classes.second_card_container}>
                        <CardContent>
                           <Grid className={classes.menu_image}>
                              <img src={menuImage} alt="menuImage" />
                           </Grid>
                           <Grid className={classes.ethereum_card}>
                              <Grid>
                                 <Box
                                    sx={{
                                       background: "#D9D9D9",
                                       height: "2.5rem",
                                       width: "2.5rem",
                                       borderRadius: "6px",
                                    }}
                                 ></Box>
                              </Grid>
                              <Grid className={classes.title_container}>
                                 <Typography
                                    sx={{
                                       fontSize: 16,
                                       fontWeight: 700,
                                    }}
                                 >
                                    ETHEREUM
                                 </Typography>
                                 <Typography
                                    sx={{
                                       fontSize: 14,
                                       color: "#d1d1d1",
                                    }}
                                    gutterBottom
                                 >
                                    ETH
                                 </Typography>
                              </Grid>
                           </Grid>
                           <Grid sx={{ mt: "10px" }}>
                              <>
                                 <Grid>
                                    <Typography
                                       sx={{
                                          color: "rgba(151, 151, 151, 1)",
                                       }}
                                    >
                                       Price
                                    </Typography>
                                    <Grid
                                       sx={{
                                          display: "flex",
                                          alignItems: "end",
                                       }}
                                    >
                                       <Typography
                                          sx={{
                                             color: "#3A3F63",
                                             fontWeight: 400,
                                             fontSize: "16px",
                                          }}
                                       >
                                          {currentEthPrice}
                                       </Typography>
                                       {dayChange && (
                                          <>
                                             <Typography
                                                sx={{
                                                   color:
                                                      dayChange > 0.0
                                                         ? "#00D0B5"
                                                         : "#FF0000",
                                                   pl: "10px",
                                                   fontSize: "14px",
                                                }}
                                             >
                                                {dayChange}%
                                             </Typography>
                                             <Grid sx={{ pl: "5px" }}>
                                                <img
                                                   src={
                                                      dayChange > 0.0
                                                         ? up_icon
                                                         : down_icon
                                                   }
                                                   alt="up_icon"
                                                />
                                             </Grid>
                                          </>
                                       )}
                                    </Grid>
                                 </Grid>
                                 <Grid sx={{ mt: "10px" }}>
                                    <Typography
                                       sx={{
                                          color: "rgba(151, 151, 151, 1)",
                                          fontSize: "12px",
                                       }}
                                    >
                                       Market Cap
                                    </Typography>
                                    <Grid
                                       sx={{
                                          display: "flex",
                                          alignItems: "end",
                                       }}
                                    >
                                       <Typography
                                          sx={{
                                             color: "#3A3F63",
                                             fontWeight: 400,
                                             fontSize: "12px",
                                          }}
                                       >
                                          {currentEthMarketCap}
                                       </Typography>
                                    </Grid>
                                 </Grid>
                                 <Grid sx={{ mt: "10px" }}>
                                    <Typography
                                       sx={{
                                          color: "#979797",
                                          fontSize: "12px",
                                       }}
                                    >
                                       Volume (24h)
                                    </Typography>
                                    <Grid
                                       sx={{
                                          display: "flex",
                                          alignItems: "end",
                                       }}
                                    >
                                       <Typography
                                          sx={{
                                             color: "#3A3F63",
                                             fontSize: "12px",
                                             fontWeight: 400,
                                          }}
                                       >
                                          {currentEthVolume}
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </>
                           </Grid>
                        </CardContent>
                     </Card>
                  </Grid>
               </Grid>
               <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  lg={10}
                  className={classes.accordion_container}
               >
                  <Typography
                     className={classes.staking_text}
                     sx={{
                        textAlign: "left !important",
                        mb: "16px",
                        fontStyle: "italic",
                        display: "flex",
                        alignItems: "center",
                     }}
                  >
                     Last update: {user.last_update}{" "}
                     {refreshing ? (
                        <CircularProgress />
                     ) : (
                        <Refresh
                           style={{
                              marginLeft: "4px",
                              color: green[500],
                              cursor: "pointer",
                           }}
                           onClick={handleRefresh}
                        />
                     )}
                  </Typography>
                  {allNodeLists.map((nodeName, i) => {
                     return (
                        <Grid key={i}>
                           <Expandable
                              Component={VimCrypto}
                              open={open}
                              handleClick={handleClick}
                              type={STYLING_PAGES.VIM_CRYPTO}
                              title={nodeName}
                              nodetitle={`${user.all_nodes[nodeName].length} NODES`}
                              balance={`BALANCE ${parseValueToEth(
                                 user.aggregate_by_list[nodeName].balance,
                                 9
                              )} ETH`}
                              income={`TOTAL INCOME ${user.aggregate_by_list[nodeName].total_rewards} ETH`}
                              avg={`AVG APR ${user.aggregate_by_list[nodeName].apr}%`}
                              name={nodeName}
                              currentEthValue={currentEthPrice}
                              nodes={user.all_nodes[nodeName]}
                              nodeId={user.all_nodes[nodeName][0].node_list_id}
                           />
                        </Grid>
                     );
                  })}
               </Grid>
            </Grid>
         </Box>

         <Box className={classes.view_mobile}>
            {viewDetail ? (
               <Box>
                  <Box className={classes.staking_detail_header}>
                     <Button
                        className={classes.button_back}
                        onClick={handleBack}
                     >
                        <KeyboardArrowLeftIcon sx={{ fontSize: "18px" }} />
                     </Button>
                     <Typography
                        className={classes.staking_detail_header_title}
                     >
                        {(nodeDetail && nodeDetail.name) || ""}
                     </Typography>
                     <Button
                        className={classes.button_link_edit}
                        onClick={handleModal}
                     >
                        Edit
                     </Button>
                  </Box>
                  <Box sx={{ mb: 4 }}>
                     <Grid container spacing={4}>
                        <Grid item xs={7}>
                           <Typography
                              className={classes.text_box}
                              sx={{ fontSize: "16px !important" }}
                           >
                              Balance
                           </Typography>
                           <Box>
                              <Typography
                                 className={classes.text_box}
                                 sx={{ fontSize: "21px !important" }}
                              >
                                 {(nodeDetail && nodeDetail.balance) || 0} ETH
                              </Typography>
                              <Typography className={classes.text_box}>
                                 {nodeDetail.usdBalance}
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={5}>
                           <Typography
                              className={classes.text_box}
                              sx={{ fontSize: "16px !important" }}
                           >
                              Total Income
                           </Typography>
                           <Box>
                              <Typography
                                 className={classes.text_box}
                                 sx={{ fontSize: "21px !important" }}
                              >
                                 {(nodeDetail && nodeDetail.income) || 0} ETH
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>

                  <Box>
                     {nodeDetail && nodeDetail.nodes && (
                        <VimCardViewData1
                           data={nodeDetail.nodes}
                           currentEthValue={currentEthPrice}
                        />
                     )}
                  </Box>

                  {nodeDetail && nodeDetail.nodes && (
                     <PopupEditNode
                        modalOpen={modalOpen}
                        onClose={handleClose}
                        onOpen={setModalOpen}
                        title={nodeDetail.name}
                        newTitle={newTitle}
                        onNewTitle={setNewTitle}
                        nodes={nodeDetail.nodes}
                        nameUpdates={nameUpdates}
                        onUpdateName={handleNameUpdate}
                        onDeleteNode={handleDeleteNode}
                        onDeleteList={handleDeleteList}
                        onUpdateList={handleUpdateList}
                     />
                  )}
               </Box>
            ) : (
               <Box>
                  <Typography
                     className={classes.staking_title}
                     sx={{ fontSize: "24px !important", mb: "10px !important" }}
                  >
                     Staking
                  </Typography>
                  <Typography
                     className={classes.staking_text}
                     sx={{ textAlign: "left !important", mb: "16px" }}
                  >
                     View balances of your current investments here.
                  </Typography>

                  <Typography
                     className={classes.staking_text}
                     sx={{
                        textAlign: "left !important",
                        mb: "16px",
                        fontStyle: "italic",
                        display: "flex",
                        alignItems: "center",
                     }}
                  >
                     Last update: {user.last_update}{" "}
                     {refreshing ? (
                        <CircularProgress />
                     ) : (
                        <Refresh
                           style={{
                              marginLeft: "4px",
                              color: green[500],
                              cursor: "pointer",
                           }}
                           onClick={handleRefresh}
                        />
                     )}
                  </Typography>

                  <Box className={classes.staking_view}>
                     {allNodeLists.map((nodeName, i) => (
                        <Box
                           key={i}
                           className={classes.staking_item_box}
                           onClick={() => handleViewDetail(nodeName)}
                        >
                           <Box className={classes.view_title}>
                              <Typography className={classes.staking_title}>
                                 {nodeName}
                              </Typography>
                              <Typography className={classes.staking_sub_title}>
                                 BALANCE{" "}
                                 {parseValueToEth(
                                    user.aggregate_by_list[nodeName].balance,
                                    9
                                 )}{" "}
                                 ETH
                              </Typography>
                           </Box>

                           <Grid container spacing={4}>
                              <Grid item xs={5}>
                                 <Typography
                                    className={classes.staking_text}
                                    sx={{ textAlign: "left !important" }}
                                 >
                                    {user.all_nodes[nodeName].length} NODES
                                 </Typography>
                              </Grid>
                              <Grid item xs={7}>
                                 <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                       <Typography
                                          className={classes.staking_text}
                                       >
                                          AVG. APR
                                       </Typography>
                                       <Typography
                                          className={classes.staking_text}
                                       >
                                          {user.aggregate_by_list[nodeName].apr}
                                          %
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                       <Typography
                                          className={classes.staking_text}
                                       >
                                          TOTAL INCOME{" "}
                                       </Typography>
                                       <Typography
                                          className={classes.staking_text}
                                       >
                                          {
                                             user.aggregate_by_list[nodeName]
                                                .total_rewards
                                          }{" "}
                                          ETH
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Box>
                     ))}
                  </Box>

                  <Divider />

                  <Box className={classes.add_node_mobile}>
                     <Grid container spacing={2}>
                        <Grid item xs={6}>
                           <Typography
                              sx={{
                                 fontSize: 16,
                                 fontWeight: 900,
                                 color: "white",
                              }}
                              gutterBottom
                              className={classes.staking_title}
                           >
                              ADD NODE
                           </Typography>
                           <FormControl
                              fullWidth
                              sx={{ mt: "6px" }}
                              size="small"
                           >
                              <InputLabel id="demo-simple-select-label">
                                 List
                              </InputLabel>
                              <Select
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 value={listName}
                                 label="List"
                                 onChange={handleChange}
                                 IconComponent={ArrowDropDownIcon}
                                 sx={{
                                    "& .MuiInputBase-input": {
                                       background: "white",
                                    },
                                    "& .MuiSelect-select": {
                                       border: "none",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                       border: "none",
                                    },
                                 }}
                                 className={classes.select_list}
                              >
                                 {allNodeLists.map((i) => {
                                    return <MenuItem value={i}>{i}</MenuItem>;
                                 })}
                                 <MenuItem value={"Add List +"}>
                                    Add List +
                                 </MenuItem>
                              </Select>
                           </FormControl>

                           {listName === "Add List +" && (
                              <>
                                 <Typography
                                    variant="body2"
                                    sx={{ mt: "10px", color: "white" }}
                                 >
                                    List Name
                                 </Typography>
                                 <Grid sx={{ mt: "6px" }}>
                                    <TextField
                                       InputProps={{
                                          style: {
                                             background: "white",
                                             height: "2rem",
                                          },
                                       }}
                                       placeholder="Ex. My List"
                                       fullWidth
                                       id="outlined-basic"
                                       variant="outlined"
                                       value={newListName}
                                       onChange={(e) =>
                                          setNewListName(e.target.value)
                                       }
                                    />
                                 </Grid>
                              </>
                           )}
                        </Grid>
                        <Grid item xs={6}>
                           <Typography
                              variant="body2"
                              sx={{ mt: "20px", color: "#3A3F63", mb: "5px" }}
                           >
                              Validator Index
                           </Typography>
                           <Grid sx={{ mt: "6px" }}>
                              <TextField
                                 InputProps={{
                                    style: {
                                       background: "white",
                                       height: "40px",
                                    },
                                 }}
                                 placeholder="Ex. 123456"
                                 fullWidth
                                 id="outlined-basic"
                                 variant="outlined"
                                 value={newNodeIndex}
                                 onChange={(e) =>
                                    setNewNodeIndex(e.target.value)
                                 }
                                 sx={{
                                    "& .MuiOutlinedInput-notchedOutline": {
                                       border: "none",
                                    },
                                 }}
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <Box sx={{ textAlign: "right" }}>
                                 <Button
                                    variant="contained"
                                    size="small"
                                    className={classes.add_btn}
                                    disabled={!newNodeIndex || addingNode}
                                    onClick={associateNode}
                                 >
                                    Add
                                 </Button>
                              </Box>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Box>
               </Box>
            )}
         </Box>

         <Dialog open={refreshing} onClose={() => {}} fullWidth>
            <DialogContent style={{ textAlign: "center", padding: "20px" }}>
               <CircularProgress />
               <Typography variant="h6" style={{ marginTop: "20px" }}>
                  Reading the blockchain...
               </Typography>
            </DialogContent>
         </Dialog>
      </DashboardLayout>
   );
};

export default Dashboard;
