import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import DashboardLayout from "../../components/DashboardLayout";

const Account = () => {
   const user = useSelector((state) => state.user);
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const handleLogout = () => {
      dispatch({ type: "LOG_OUT_USER" });
      navigate("/auth/login");
   };

   return (
      <DashboardLayout>
         <Box style={{ margin: "10px 0" }}>
            {user.full_name || "Your Account"}
         </Box>
         <Box style={{ margin: "10px 0" }}>
            <Typography
               style={{
                  cursor: "pointer",
                  textDecoration: "underlined",
               }}
               onClick={handleLogout}
            >
               Log Out
            </Typography>
         </Box>
      </DashboardLayout>
   );
};

export default Account;
