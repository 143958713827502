import { Check, Close, Delete } from "@mui/icons-material";
import {
   Card,
   CardContent,
   CircularProgress,
   Grid,
   TextField,
   Typography,
} from "@mui/material";
import React, { useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";

import { callApi } from "../../../api/api";

import { useStyles } from "./styles";

const WalletCard = ({
   item,
   edit,
   requesting,
   nameUpdates,
   onNameUpdate,
   onUpdateName,
   onEdit,
   walletInfo = {
      protocol: "BTC",
   },
}) => {
   const classes = useStyles();
   const dispatch = useDispatch();
   const [deleting, setDeleting] = useState();

   return (
      <Card className={classes.etherum_card}>
         <CardContent>
            <Grid sx={{ display: "flex", alignItems: "center" }}>
               {edit === item.id ? (
                  <TextField
                     size="small"
                     placeholder="Edit name"
                     value={nameUpdates[item.id]}
                     name="name"
                     fullWidth
                     style={{ margin: "0 10px" }}
                     onChange={(e) => onNameUpdate(item.id, e.target.value)}
                  />
               ) : (
                  <Typography className={classes.nick_name}>
                     {item.nickname || "Nickname"}
                  </Typography>
               )}
               <Grid className={classes.edit_icon}>
                  {edit === item.id ? (
                     <>
                        <Check
                           style={{
                              cursor: "pointer",
                              color: requesting ? "#ddd" : "#00FF00",
                           }}
                           onClick={onUpdateName}
                        />
                        <Close
                           style={{ cursor: "pointer" }}
                           onClick={() => onEdit()}
                           color="error"
                        />
                     </>
                  ) : (
                     <>
                        <MdModeEdit
                           style={{
                              color: "#858ebd",
                              height: "18px",
                              width: "18px",
                              cursor: "pointer",
                           }}
                           onClick={() => onEdit(item.id)}
                        />
                        {deleting && deleting === item.id ? (
                           <CircularProgress
                              style={{
                                 color: "#858ebd",
                                 height: "18px",
                                 width: "18px",
                                 marginLeft: "2px",
                              }}
                           />
                        ) : (
                           <Delete
                              style={{
                                 color: "#ff0000",
                                 height: "18px",
                                 width: "18px",
                                 cursor: "pointer",
                                 marginLeft: "2px",
                              }}
                              onClick={() => {
                                 const confirmDelete = window.confirm(
                                    "Are you sure you want to delete this wallet?"
                                 );
                                 if (confirmDelete) {
                                    setDeleting(item.id);

                                    callApi({
                                       url: `/api/v1/wallets/${item.id}`,
                                       method: "DELETE",
                                    })
                                       .then((res) =>
                                          dispatch({
                                             type: "SET_USER",
                                             payload: res,
                                          })
                                       )
                                       .finally(() => setDeleting());
                                 }
                              }}
                           />
                        )}
                     </>
                  )}
               </Grid>
            </Grid>
            <Grid>
               <Typography className={classes.balance}>Balance</Typography>
               <Grid sx={{ display: "flex", alignItems: "end" }}>
                  <Typography className={classes.total_coin}>
                     {item.usd_balance.token} {walletInfo.protocol}
                  </Typography>
               </Grid>
            </Grid>
            <Typography
               sx={{
                  fontSize: "14px",
                  color: "#3A3F63",
                  fontWeight: 400,
               }}
            >
               {item.usd_balance.usd.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
               })}{" "}
               USD
            </Typography>

            <Typography className={classes.deposite}>Address</Typography>
            <Typography className={classes.info}>
               {item.address.substring(0, 10)}...
            </Typography>
         </CardContent>
      </Card>
   );
};

export default WalletCard;
