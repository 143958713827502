const initialState = {
   user: null,
};

function userReducer(state = initialState, action) {
   switch (action.type) {
      case "SET_USER":
         return {
            ...state,
            user: action.payload,
         };
      case "LOG_OUT_USER": {
         return initialState;
      }
      default:
         return state;
   }
}

export default userReducer;
