import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
   card_container: {
      background: "#3a3f63",
      borderRadius: "16px",
   },
   add_btn_container: {
      display: "flex",
      justifyContent: "end",
      padding: "8px 16px",
   },
   add_btn: {
      background: "#4d76ff",
      borderRadius: "10px",
      width: "73px",
      height: "31px",
      textTransform: "capitalize",
   },
   second_card_container: {
      marginTop: "2rem",
      borderRadius: "16px",
   },
   ethereum_card: {
      display: "flex",
      alignItems: "center",
   },
   title_container: {
      paddingLeft: "1rem",
   },
   accordion_container: {
      paddingLeft: "40px",
      [theme.breakpoints.down("md")]: {
         padding: "1rem 0 0 0",
      },
   },
   staking_title: {
      color: "#3a3f63",
      fontSize: "16px",
      fontWeight: 900,
      marginBottom: "16px",
      textTransform: "uppercase",
   },
   staking_sub_title: {
      marginLeft: "auto",
      color: "#3A3F63",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
   },
   horizontal_scroll_wrapper: {
      position: "absolute",
      display: "block",
      top: 0,
      left: 0,
      width: "80px",
      maxHeight: "500px",
      margin: 0,
      background: "#abc",
      overflowY: "auto",
      overflowX: "hidden",
      transform: "rotate(-90deg) translateY(-80px)",
      transformOrigin: "right top",
   },
   slide_item: {
      display: "block",
      margin: "10px",
   },
   header_section: {
      display: "flex",
      alignItems: "center",
      marginBottom: "18px",
   },
   title_section: {
      color: "#3A3F63",
      fontSize: "18px",
      lineHeight: "20px",
      textTransform: "uppercase",
      fontWeight: 700,
   },
   button_expand: {
      color: "#979797",
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: 400,
      textDecoration: "underline",
      padding: "0",
      textTransform: "capitalize",
      margin: "0 12px",
   },
   button_wallet: {
      color: "#4B5EFF",
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: 400,
      padding: "0",
      textTransform: "capitalize",
   },
   select_list: {
      ".MuiSelect-select": {
         border: "none",
      },
   },
   view_desktop: {
      display: "block",

      [theme.breakpoints.down("sm")]: {
         display: "none",
      },
   },
   view_mobile: {
      display: "none",

      [theme.breakpoints.down("sm")]: {
         display: "block",
      },
   },
   etherum_card_conatiner: {
      padding: "2rem 1rem 1rem",
   },
   etherum_card: {
      position: "relative",
      borderRadius: "14px",
      boxShadow: "2px 4px 8px 0px rgb(179 192 231 / 32%) !important",
      border: "1px solid #1F2552",

      [theme.breakpoints.down("sm")]: {
         display: "inline-flex",
         marginRight: "16px",
         width: "9rem !important",
         height: "100%",
      },
   },
   nick_name: {
      fontSize: "16px",
      fontWeight: 900,
      textTransform: "uppercase",
      color: "#3A3F63",

      [theme.breakpoints.down("sm")]: {
         fontSize: "12px",
      },
   },
   edit_icon: {
      paddingLeft: "5px",
   },
   balance: {
      fontSize: "14px",
      color: "#979797",
      paddingTop: "10px",

      [theme.breakpoints.down("sm")]: {
         fontSize: "10px",
         paddingTop: "0px",
      },
   },
   total_coin: {
      color: "#3A3F63",
      fontWeight: 400,

      [theme.breakpoints.down("sm")]: {
         fontSize: "13px",
      },
   },
   up_down: {
      color: "#00D0B5",
      fontSize: "14px",
      paddingLeft: "10px",
   },
   deposite: {
      fontSize: "12px",
      color: "#979797",
      paddingTop: "10px",

      [theme.breakpoints.down("sm")]: {
         paddingTop: "4px",
      },
   },
   info: {
      fontSize: "12px",
      color: "#3A3F63",
   },
   view_qr: {
      fontSize: "12px",
      color: "#979797",
      paddingTop: "10px",
      textDecoration: "underline",

      [theme.breakpoints.down("sm")]: {
         fontSize: "10px",
         paddingTop: "4px",
      },
   },
   avtar: {
      height: "44px",
      width: "44px",
      background: "#D9D9D9",
      borderRadius: "50%",
      position: "absolute",
      transform: "translate(170px, -225px)",
   },
}));
