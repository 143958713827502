import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
   card_container: {
      background: "#3a3f63",
      borderRadius: "16px",
   },
   add_btn_container: {
      display: "flex",
      justifyContent: "end",
      padding: "8px 16px",
   },
   add_btn: {
      background: "#4d76ff",
      borderRadius: "10px",
      width: "73px",
      height: "31px",
      textTransform: "capitalize",

      [theme.breakpoints.down("sm")]: {
         marginTop: "16px",
      }
   },
   second_card_container: {
      marginTop: "2rem",
      borderRadius: "16px",
      boxShadow: "0px 4px 16px 0px rgba(179, 192, 231, 0.32)",
   },
   menu_image: {
      display: "flex",
      justifyContent: "end",
   },
   ethereum_card: {
      display: "flex",
      alignItems: "center",
   },
   title_container: {
      paddingLeft: "1rem",
   },
   accordion_container: {
      paddingLeft: "40px",
      [theme.breakpoints.down("md")]: {
         padding: "1rem 0 0 0",
      },
      // [theme.breakpoints.down("sm")]: {
      //    display: "none",
      // },
   },
   staking_view: {
      display: "none",
      width: "100%",
      
      [theme.breakpoints.down("sm")]: {
         display: "block",
      },
   },
   staking_item_box: {
      background: "#fff",
      borderRadius: "8px",
      marginBottom: "16px",
      width: "100%",
      padding: "16px",
      boxShadow: "0px 4px 16px 0px rgba(179, 192, 231, 0.32)",
      cursor: "pointer",
   },
   view_title: {
      display: "flex",
      alignItems: "center",
   },
   staking_title: {
      color: "#3a3f63",
      fontSize: "16px",
      fontWeight: 900,
      marginBottom: "16px",
      textTransform: "uppercase",
   },
   staking_sub_title: {
      marginLeft: "auto",
      color: "#3A3F63",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
   },
   staking_text: {
      color: "#3A3F63",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      textAlign: "right",
   },
   view_desktop: {
      display: "block",

      [theme.breakpoints.down("sm")]: {
         display: "none",
      }
   },
   view_mobile: {
      display: "none",

      [theme.breakpoints.down("sm")]: {
         display: "block",
      },
   },
   add_node_mobile: {
      display: "none",
      marginTop: "30px",

      [theme.breakpoints.down("sm")]: {
         display: "block",
      },
   },
   select_list: {
      ".MuiSelect-select": {
         border: "none",
      }
   },
   staking_detail: {

   },
   staking_detail_header: {
      display: "flex",
      alignItems: "center",
      marginBottom: "24px",
   },
   button_back: {
      width: "24px",
      height: "24px",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "14px",
      background: "#fff",
      borderRadius: "4px",
      padding: "0",
      minWidth: "20px",
   },
   button_link_edit: {
      color: "#979797",
      textDecoration: "underline",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      marginLeft: "14px",
      minWidth: "20px",
      textTransform: "capitalize",
   },
   staking_detail_header_title: {
      color: "#3A3F63",
      fontSize: "21px",
      fontWeight: 700,
      textTransform: "uppercase",
   },
   text_box: {
      color: "#3A3F63",
      fontSize: "14px",
      fontWeight: 400,
   }
}));
