import {
   Check,
   Close,
   CloseRounded,
   ContentCopyRounded,
   Delete,
} from "@mui/icons-material";
import {
   AppBar,
   Box,
   Button,
   Card,
   CardContent,
   CircularProgress,
   Dialog,
   DialogContent,
   DialogTitle,
   Divider,
   Grid,
   IconButton,
   Paper,
   Tab,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Tabs,
   TextField,
   Typography,
} from "@mui/material";
import React, { useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { callApi } from "../../../api/api";

import { useStyles } from "./styles";

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

const Ethereum = ({ title }) => {
   const classes = useStyles();
   const user = useSelector((state) => state.user);
   const dispatch = useDispatch();
   const [edit, setEdit] = useState();
   const [deleting, setDeleting] = useState();
   const [nameUpdates, setNameUpdates] = useState({});
   const [error, setError] = useState();
   const [requesting, setRequesting] = useState();
   const [open, setOpen] = useState(false);
   const [findingWallet, setFindingWallet] = useState(false);
   const [foundWallet, setFoundWallet] = useState();
   const [value, setValue] = React.useState(0);
   const [transactions, setTransactions] = useState();
   const [loadingTransactions, setLoadingTransactions] = useState(false);

   const handleChange = (event, newValue) => {
      if (newValue === 1) {
         getTransactions(foundWallet.id);
      }

      setValue(newValue);
   };

   const handleNameUpdate = (index, val) => {
      setNameUpdates({
         ...nameUpdates,
         [index]: val,
      });
   };

   const handleUpdateName = () => {
      setRequesting(true);

      callApi({
         url: `/api/v1/wallets/${edit}`,
         method: "PUT",
         data: {
            wallet: {
               name: nameUpdates[edit],
            },
         },
      })
         .then((res) => {
            if (res.errors) {
               setError("Error updating, try again.");
            } else {
               dispatch({ type: "SET_USER", payload: res });
               setEdit();
            }
         })
         .finally(() => setRequesting());
   };

   const findWallet = (address) => {
      setFindingWallet(true);

      const walletWithAddress = user.all_wallet_lists
         .filter((i) => i.protocol === "ethereum" && i.list_name === title)
         .map((i) => i.wallets)
         .flat()
         .find((i) => i.address === address);

      setFoundWallet(walletWithAddress);

      setFindingWallet(false);
   };

   const handleClose = () => {
      setOpen(false);
      setTransactions();
      setFoundWallet();
      handleChange(null, 0);
   };

   const getTransactions = (id) => {
      setLoadingTransactions(true);

      callApi({
         url: `/api/v1/wallets/${id}/transactions`,
         method: "GET",
      })
         .then((res) => {
            setTransactions(res);
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => setLoadingTransactions(false));
   };

   const stampToDate = (stamp) => {
      const stampInt = parseInt(stamp);
      const date = new Date(stampInt * 1000);
      return date.toLocaleString();
   };

   const weiToEth = (wei) => {
      const weiInt = parseInt(wei);
      const ether = weiInt / 1e18;
      return ether.toFixed(2);
   };

   return (
      <>
         <Divider />
         <Grid container spacing={3} className={classes.etherum_card_conatiner}>
            {user.all_wallet_lists
               .filter(
                  (i) => i.protocol === "ethereum" && i.list_name === title
               )
               .map((i, index) => {
                  return i.wallets.map((item) => {
                     return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                           <Card className={classes.etherum_card}>
                              <CardContent>
                                 <Grid
                                    sx={{
                                       display: "flex",
                                       alignItems: "center",
                                    }}
                                 >
                                    {edit === item.id ? (
                                       <TextField
                                          size="small"
                                          placeholder="Edit name"
                                          value={nameUpdates[item.id]}
                                          name="name"
                                          fullWidth
                                          style={{ margin: "0 10px" }}
                                          onChange={(e) =>
                                             handleNameUpdate(
                                                item.id,
                                                e.target.value
                                             )
                                          }
                                       />
                                    ) : (
                                       <Typography
                                          className={classes.nick_name}
                                       >
                                          {item.nickname || "Nickname"}
                                       </Typography>
                                    )}
                                    <Grid className={classes.edit_icon}>
                                       {edit === item.id ? (
                                          <>
                                             <Check
                                                style={{
                                                   cursor: "pointer",
                                                   color: requesting
                                                      ? "#ddd"
                                                      : "#00FF00",
                                                }}
                                                onClick={handleUpdateName}
                                             />
                                             <Close
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setEdit()}
                                                color="error"
                                             />
                                          </>
                                       ) : (
                                          <>
                                             <MdModeEdit
                                                style={{
                                                   color: "#858ebd",
                                                   height: "18px",
                                                   width: "18px",
                                                   cursor: "pointer",
                                                }}
                                                onClick={() => setEdit(item.id)}
                                             />
                                             {deleting &&
                                             deleting === item.id ? (
                                                <CircularProgress
                                                   style={{
                                                      color: "#858ebd",
                                                      height: "18px",
                                                      width: "18px",
                                                      marginLeft: "2px",
                                                   }}
                                                />
                                             ) : (
                                                <Delete
                                                   style={{
                                                      color: "#ff0000",
                                                      height: "18px",
                                                      width: "18px",
                                                      cursor: "pointer",
                                                      marginLeft: "2px",
                                                   }}
                                                   onClick={() => {
                                                      const confirmDelete =
                                                         window.confirm(
                                                            "Are you sure you want to delete this wallet?"
                                                         );
                                                      if (confirmDelete) {
                                                         setDeleting(item.id);

                                                         callApi({
                                                            url: `/api/v1/wallets/${item.id}`,
                                                            method: "DELETE",
                                                         })
                                                            .then((res) =>
                                                               dispatch({
                                                                  type: "SET_USER",
                                                                  payload: res,
                                                               })
                                                            )
                                                            .finally(() =>
                                                               setDeleting()
                                                            );
                                                      }
                                                   }}
                                                />
                                             )}
                                          </>
                                       )}
                                    </Grid>
                                 </Grid>
                                 <Grid>
                                    <Typography className={classes.balance}>
                                       Balance
                                    </Typography>
                                    <Grid
                                       sx={{
                                          display: "flex",
                                          alignItems: "end",
                                       }}
                                    >
                                       <Typography
                                          className={classes.total_coin}
                                       >
                                          {item.usd_balance.token} ETH
                                       </Typography>
                                    </Grid>
                                 </Grid>
                                 <Typography
                                    sx={{
                                       fontSize: "14px",
                                       color: "#3A3F63",
                                       fontWeight: 400,
                                    }}
                                 >
                                    {item.usd_balance.usd.toLocaleString(
                                       "en-US",
                                       {
                                          style: "currency",
                                          currency: "USD",
                                       }
                                    )}{" "}
                                    USD
                                 </Typography>

                                 <Box
                                    style={{
                                       margin: "10px 0",
                                    }}
                                 >
                                    <Button
                                       variant="contained"
                                       color="primary"
                                       onClick={() => {
                                          findWallet(item.address);
                                          setOpen(true);
                                       }}
                                    >
                                       Details
                                    </Button>
                                 </Box>
                              </CardContent>
                           </Card>
                        </Grid>
                     );
                  });
               })}
         </Grid>

         <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>
               <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                  style={{ position: "absolute", right: "8px", top: "8px" }}
               >
                  <CloseRounded />
               </IconButton>
               💰 Details
            </DialogTitle>
            <DialogContent style={{ textAlign: "center", padding: "20px" }}>
               {findingWallet ? (
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                     }}
                  >
                     <CircularProgress />
                  </Box>
               ) : (
                  <Box sx={{ width: "100%" }}>
                     <Box
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           padding: "10px",
                           backgroundColor: "#fff",
                           borderRadius: "4px",
                           boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                           marginBottom: "20px",
                        }}
                     >
                        <Box
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "8px",
                           }}
                        >
                           <Typography
                              style={{
                                 fontWeight: "normal",
                                 fontSize: "0.9rem",
                                 color: "#333",
                              }}
                           >
                              {foundWallet?.address}
                           </Typography>
                           <IconButton
                              onClick={() => {
                                 navigator.clipboard.writeText(
                                    foundWallet?.address
                                 );
                                 alert("Address copied");
                              }}
                              aria-label="copy address"
                           >
                              <ContentCopyRounded fontSize="small" />
                           </IconButton>
                        </Box>
                        <Box
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "4px",
                           }}
                        >
                           <Typography
                              style={{
                                 fontWeight: "bold",
                                 fontSize: "0.9rem",
                                 color: "#333",
                              }}
                           >
                              ETH Balance:
                           </Typography>
                           <Typography
                              style={{
                                 fontWeight: "normal",
                                 fontSize: "0.9rem",
                                 color: "#333",
                              }}
                           >
                              {foundWallet?.usd_balance?.usd?.toLocaleString(
                                 "en-US",
                                 { style: "currency", currency: "USD" }
                              )}
                           </Typography>
                        </Box>

                        <Box
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "4px",
                           }}
                        >
                           <Typography
                              style={{
                                 fontWeight: "bold",
                                 fontSize: "0.9rem",
                                 color: "#333",
                              }}
                           >
                              ERC 20 Balance:
                           </Typography>
                           <Typography
                              style={{
                                 fontWeight: "normal",
                                 fontSize: "0.9rem",
                                 color: "#333",
                              }}
                           >
                              {foundWallet?.erc_usd_bal?.toLocaleString(
                                 "en-US",
                                 { style: "currency", currency: "USD" }
                              )}
                           </Typography>
                        </Box>

                        <Box
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                           }}
                        >
                           <Typography
                              style={{
                                 fontWeight: "bold",
                                 fontSize: "0.9rem",
                                 color: "#333",
                              }}
                           >
                              Total Balance:
                           </Typography>
                           <Typography
                              style={{
                                 fontWeight: "normal",
                                 fontSize: "0.9rem",
                                 color: "#333",
                              }}
                           >
                              {foundWallet?.total_bal?.toLocaleString("en-US", {
                                 style: "currency",
                                 currency: "USD",
                              })}
                           </Typography>
                        </Box>
                     </Box>
                     <AppBar
                        position="static"
                        style={{ backgroundColor: "white" }}
                     >
                        <Tabs
                           value={value}
                           onChange={handleChange}
                           aria-label="basic tabs example"
                        >
                           <Tab label="ERC 20 Tokens" />
                           <Tab label="Transactions" />
                        </Tabs>
                     </AppBar>
                     <TabPanel value={value} index={0}>
                        {foundWallet?.erc?.map((i) => {
                           return (
                              <div
                                 style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "10px",
                                    backgroundColor: "#f5f5f5",
                                    borderRadius: "4px",
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                 }}
                              >
                                 <div
                                    style={{
                                       display: "flex",
                                       alignItems: "center",
                                    }}
                                 >
                                    {i.logos[0] && (
                                       <img
                                          src={i.logos[0].uri}
                                          alt="logo"
                                          height="38"
                                          width="38"
                                          style={{ marginRight: "10px" }}
                                       />
                                    )}
                                    <Typography
                                       style={{
                                          fontWeight: "bold",
                                          fontSize: "1rem",
                                          color: "#333",
                                       }}
                                    >
                                       {i.name}
                                    </Typography>
                                 </div>
                                 <div style={{ textAlign: "right" }}>
                                    <Typography
                                       style={{
                                          fontSize: "0.8rem",
                                          color: "#666",
                                       }}
                                    >
                                       Balance
                                    </Typography>
                                    <Typography
                                       style={{
                                          fontWeight: "bold",
                                          fontSize: "1.2rem",
                                          color: "#333",
                                       }}
                                    >
                                       {i.current_usd_price.toLocaleString(
                                          "en-US",
                                          {
                                             style: "currency",
                                             currency: "USD",
                                          }
                                       )}
                                    </Typography>
                                 </div>
                              </div>
                           );
                        })}
                     </TabPanel>
                     <TabPanel value={value} index={1}>
                        {loadingTransactions ? (
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                              }}
                           >
                              <CircularProgress />
                           </Box>
                        ) : (
                           <Box>
                              <TableContainer component={Paper}>
                                 <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                 >
                                    <TableHead>
                                       <TableRow>
                                          <TableCell>
                                             <Typography fontWeight="bold">
                                                Date
                                             </Typography>
                                          </TableCell>
                                          <TableCell>
                                             <Typography fontWeight="bold">
                                                From
                                             </Typography>
                                          </TableCell>
                                          <TableCell align="right">
                                             <Typography fontWeight="bold">
                                                To
                                             </Typography>
                                          </TableCell>
                                          <TableCell align="right">
                                             <Typography fontWeight="bold">
                                                Value
                                             </Typography>
                                          </TableCell>
                                       </TableRow>
                                    </TableHead>
                                    <TableBody>
                                       {transactions?.map((i) => (
                                          <TableRow
                                             sx={{
                                                "&:last-child td, &:last-child th":
                                                   { border: 0 },
                                             }}
                                             key={i.hash}
                                          >
                                             <TableCell
                                                component="th"
                                                scope="row"
                                             >
                                                <Typography color="#666">
                                                   {stampToDate(i.timeStamp)}
                                                </Typography>
                                             </TableCell>
                                             <TableCell
                                                component="th"
                                                scope="row"
                                             >
                                                <Typography color="#333">
                                                   {i.from.substring(0, 10)}
                                                   ...
                                                </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                <Typography color="#666">
                                                   {i.to.substring(0, 10)}...
                                                </Typography>
                                             </TableCell>
                                             <TableCell align="right">
                                                <Typography
                                                   fontWeight="bold"
                                                   color="#333"
                                                >
                                                   {weiToEth(i.value)} ETH
                                                </Typography>
                                             </TableCell>
                                          </TableRow>
                                       ))}
                                    </TableBody>
                                 </Table>
                              </TableContainer>
                           </Box>
                        )}
                     </TabPanel>
                  </Box>
               )}
            </DialogContent>
         </Dialog>
      </>
   );
};

export default Ethereum;
