import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
   crad_container1: {
      width: "100%",
   },
   vim_crypto_card: {
      padding: "17px 0 30px 0",
   },
   card_data1: {
      boxShadow: "2px 4px 8px 0px rgba(179, 192, 231, 0.8)",
      borderRadius: "10px",
   },
}));
