import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { getEthValue } from "../../../utils";
import VimCardViewData1 from "../../VimCardViewData1";

import { useStyles } from "./styles";

const VimCrypto = ({ name, currentEthValue }) => {
   const user = useSelector((state) => state.user);
   const classes = useStyles();

   return (
      <>
         <Divider sx={{ margin: "0 24px" }} />
         <Grid container sx={{ p: "0.5rem 1.6rem" }}>
            <Grid className={classes.diagram_container}>
               <Grid item xs={12} sm={12} md={6} lg={6} sx={{ pr: "1rem" }}>
                  <Grid>
                     <Typography sx={{ color: "#979797" }}>Balance</Typography>
                     <Grid sx={{ display: "flex", alignItems: "end" }}>
                        <Typography
                           sx={{
                              fontSize: "16px",
                              color: "#3A3F63",
                              fontWeight: 600,
                           }}
                        >
                           {getEthValue(
                              user.aggregate_by_list[name].balance,
                              currentEthValue,
                              9
                           ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                           })}
                        </Typography>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Grid>
                     <Typography sx={{ color: "#979797" }}>
                        Total Income
                     </Typography>
                     <Grid sx={{ display: "flex", alignItems: "end" }}>
                        <Typography
                           sx={{
                              fontSize: "16px",
                              color: "#3A3F63",
                              fontWeight: 600,
                           }}
                        >
                           {user.aggregate_by_list[name].total_rewards} ETH
                        </Typography>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
            <Grid sx={{ width: "100%" }}>
               <VimCardViewData1
                  data={user.all_nodes[name]}
                  currentEthValue={currentEthValue}
               />
            </Grid>
         </Grid>
      </>
   );
};

export default VimCrypto;
