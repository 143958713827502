import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
   header_container: {
      background: "#4C71FF",
      height: "84px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      [theme.breakpoints.down("sm")]: {
         display: "none",
      },
   },
   header_mobile: {
      display: "none",

      [theme.breakpoints.down("sm")]: {
         display: "flex",
         alignItems: "center",
         justifyContent: "flex-end",
         background: "#4C71FF",
         padding: "40px 30px 16px",
      },
   },
   name_container: {
      display: "flex",
      alignItems: "center",
      paddingRight: "24px",

      [theme.breakpoints.down("sm")]: {
         display: "none",
      },
   },
   name: {
      color: "rgba(179, 192, 231, 1)",
      paddingRight: "10px",
   },
   avatar: {
      height: "32px",
      width: "32px",
   },
}));
