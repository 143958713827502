import { createTheme } from "@mui/material";

export const theme = createTheme({
   transitions: {
      duration: {
         shortest: 150,
         shorter: 200,
         short: 250,
         // most basic recommended timing
         standard: 300,
         // this is to be used in complex animations
         complex: 375,
         // recommended when something is entering screen
         enteringScreen: 225,
         // recommended when something is leaving screen
         leavingScreen: 195,
      },
      easing: {
         // This is the most common easing curve.
         easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
         // Objects enter the screen at full velocity from off-screen and
         // slowly decelerate to a resting point.
         easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
         // Objects leave the screen at full velocity. They do not decelerate when off-screen.
         easeIn: "cubic-bezier(0.4, 0, 1, 1)",
         // The sharp curve is used by objects that may return to the screen at any time.
         sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
      },
   },
   palette: {
      primary: {
         main: "#282F5F",
         dark: "#1F2552",
      },
      secondary: {
         main: "#FF8B64",
      },
      text: {
         primary: "rgba(58, 63, 99, 1)",
      },
   },

   typography: {
      fontFamily: "Rubik, sans-serif",
      color: "rgb(58, 63, 99)",
      letterSpacing: "0.05em",
      // You can customize other typography styles here
   },
   overrides: {
      MuiCssBaseline: {
         "@global": {
            "@font-face": {
               fontFamily: "Rubik",
               src: "url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap)",
               // You can add additional font properties if needed
            },
         },
      },
   },
});
