import ChartIcon from "../assets/Icons/Chart/00.svg";
import ActiveChartIcon from "../assets/Icons/Chart/11.svg";
import FinacialIcon from "../assets/Icons/Financial/00.svg";
import ActiveFinacialIcon from "../assets/Icons/Financial/11.svg";
import SettingIcon from "../assets/Icons/Setting personal/00.svg";
import ActiveSettingIcon from "../assets/Icons/Setting personal/11.svg";
import InBoxIcon from "../assets/Icons/inbox/00.svg";
import ActiveInBoxIcon from "../assets/Icons/inbox/11.svg";
import { DashboardURLPath } from "../helpers/routes";

export const STYLING_PAGES = {
   MYLIST_PAGE: "MYLIST_PAGE",
   EXAMPLELIST2_PAGE: "EXAMPLELIST2_PAGE",
   VIM_CRYPTO: "VIM_CRYPTO",
   BITCOIN_PAGE: "BITCOIN_PAGE",
   ETHEREUM_PAGE: "ETHEREUM_PAGE",
};

const IMG = ({ src }) => (
   <img width="24px" height="24px" src={src} alt={src}></img>
);
export const icons = [
   {
      icon: <IMG src={ChartIcon}></IMG>,
      aIcon: <IMG src={ActiveChartIcon}></IMG>,
      path: DashboardURLPath,
      title: "Staking & Mining",
      subTitle: "Staking",
   },
   {
      aIcon: <IMG src={ActiveInBoxIcon}></IMG>,
      icon: <IMG src={InBoxIcon}></IMG>,
      path: "/wallet",
      title: "Wallets",
      subTitle: "Wallets",
   },
   {
      aIcon: <IMG src={ActiveFinacialIcon}></IMG>,
      icon: <IMG src={FinacialIcon}></IMG>,
      path: "/opportunities",
      title: "Opportunities",
      subTitle: "Opportunities",
   },
   {
      aIcon: <IMG src={ActiveSettingIcon}></IMG>,
      icon: <IMG src={SettingIcon}></IMG>,
      path: "/account",
      title: "Account",
      subTitle: "Account",
   },
];

export const parseValueToEth = (val, power = 18) => {
   if (!val || val == 0) {
      return 0;
   }

   return (val / Math.pow(10, power)).toFixed(3);
};

export const getEthValue = (balance, currentEthPrice, power = 18) => {
   if (!currentEthPrice) {
      return "-";
   }

   const price = currentEthPrice.replace(/\$|,/g, "");
   const ethVal = parseValueToEth(balance, power);
   const value = price * ethVal;

   return value;
};

export const combineErcArrays = (ethereum) => {
   return ethereum.reduce((acc, curr) => {
      const ercsWithAddress = curr.erc.map((ercEntry) => ({
         ...ercEntry,
         address: curr.address,
      }));
      return acc.concat(ercsWithAddress);
   }, []);
};
