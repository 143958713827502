import {
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   Container,
   Grid,
   Typography,
   useMediaQuery,
   useTheme,
} from "@mui/material";
import React from "react";

import DashboardLayout from "../../components/DashboardLayout";

const opportunities = [
   {
      name: "Introduction to Crypto | Investor Review",
      description:
         "Deep dive into your interest in crypto & blockchain, review of your current & prospective technology needs, and overview of sophisticated investor opportunities in the ecosystem",
      minimumCheckSize: "Not Applicable",
      estimatedYield: "Not Applicable",
   },
   {
      name: "Technology Implementation Engagement",
      description:
         "Engagement designed to implement the specific blockchain tools and processes you need to invest, use, and build on the blockchain. These tools can range from fiat onboarding, wallets, and DeFi infrastructure to financial reporting and legal structuring",
      minimumCheckSize: "Not Applicable",
      estimatedYield: "Not Applicable",
   },
   {
      name: "Dedicated SPV: Ethereum Staking Yield",
      description:
         "Dedicated capital-friendly SPV designed for sophisticated investors seeking Ethereum staking yield. The Ethereum network is the second largest blockchain by market capitalization and offers yield opportunities for supporting the security of the blockchain and processing transactions through Ethereum nodes",
      minimumCheckSize: "32 ETH",
      estimatedYield: "3% - 15%",
   },
   {
      name: "Dedicated SPV: Bitcoin Mining Yield",
      description:
         "Dedicated capital-friendly SPV designed for sophisticated investors seeking yield from Bitcoin Mining. Bitcoin is the largest cryptocurrency by market capitalization and offers yield opportunities through “mining” operations that secure the blockchain and process transactions. VIM maintains relationships with large BTC mines in Europe and North America",
      minimumCheckSize: "$5 million+",
      estimatedYield: "5% - 6%",
   },
   {
      name: "Fund & Bespoke Opportunities",
      description:
         "VIM can facilitate all the technology and SPV needs of sophisticated investors seeking exposure to institutional funds, decentralized finance opportunities, industry experts",
      minimumCheckSize: "Not Applicable",
      estimatedYield: "Not Applicable",
   },
];

const OpportunityCard = ({ title, checkSize, tokenYield, description }) => {
   const theme = useTheme();
   const isMd = useMediaQuery(theme.breakpoints.down("xl"));
   const isSm = useMediaQuery(theme.breakpoints.down("sm"));

   const getWidth = () => {
      if (isMd) {
         return 280;
      }

      if (isSm) {
         return "90vw";
      }

      return 344;
   };

   return (
      <Card
         style={{
            width: getWidth(),
            height: 500,
            margin: 16,
            overflow: "scroll",
            position: "relative",
            borderRadius: "12px",
         }}
      >
         <CardContent>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 700 }}>
               {title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
               Minimum Check Size
            </Typography>
            <Typography variant="body1" gutterBottom>
               {checkSize}
            </Typography>
            <Typography variant="body2" color="textSecondary">
               Estimated Token Yield
            </Typography>
            <Typography variant="body1" gutterBottom>
               {tokenYield}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
               {description}
            </Typography>
            <CardActions>
               <Button
                  onClick={() =>
                     window.location.assign("https://calendly.com/tyler-vim")
                  }
                  variant="contained"
                  style={{ marginTop: 12, backgroundColor: "#4c70ff" }}
               >
                  Schedule a Meeting
               </Button>
            </CardActions>
         </CardContent>
      </Card>
   );
};

const Opportunities = () => {
   const theme = useTheme();
   const isSm = useMediaQuery(theme.breakpoints.down("sm"));

   return (
      <DashboardLayout>
         <Container>
            {isSm ? (
               <Box style={{ width: "100%", margin: "0 auto" }}>
                  {opportunities.map((i, index) => (
                     <OpportunityCard
                        title={i.name}
                        checkSize={i.minimumCheckSize}
                        tokenYield={i.estimatedYield}
                        description={i.description}
                     />
                  ))}
               </Box>
            ) : (
               <Grid container spacing={6}>
                  {opportunities.map((i, index) => (
                     <Grid item xs={12} sm={8} md={4} key={index}>
                        <OpportunityCard
                           title={i.name}
                           checkSize={i.minimumCheckSize}
                           tokenYield={i.estimatedYield}
                           description={i.description}
                        />
                     </Grid>
                  ))}
               </Grid>
            )}
         </Container>
      </DashboardLayout>
   );
};

export default Opportunities;
