import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
   logo_container: {
      background: "#e8ecf8",
      height: "64px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 25px 0 33px",

      [theme.breakpoints.down("sm")]: {
         background: "#fff",
         display: "none",
      },
   },
   logo: {
      height: "45px",
   },
   logo_img: {
      height: "100%",
      [theme.breakpoints.down("sm")]: {
         display: "none",
      },
   },
   faq_logo: {
      [theme.breakpoints.down("sm")]: {
         display: "none",
      },
   },
   logo_img_mobile: {
      height: "100%",
   },
   view_logo: {
      background: "#fff",
      display: "none",
      padding: "30px 0",
      [theme.breakpoints.down("sm")]: {
         display: "block",
         textAlign: "center",
      },
   },
   mainbox: {
      background: "#e8ecf8",
      display: "flex",
      height: "calc(100vh - 64px)",
      justifyContent: "center",
      alignItems: "center",

      [theme.breakpoints.down("sm")]: {
         background: "#fff",
         height: "calc(100vh - 99px)",
         alignItems: "start",
      },
   },
   form_grid: {
      [theme.breakpoints.down("sm")]: {
         width: "100%",
      },
   },
   heading: {
      display: "flex",
      fontWeight: 900,
      fontSize: 37,
      justifyContent: "center",
      color: "#3a3f63",

      [theme.breakpoints.down("sm")]: {
         fontSize: "40px",
         marginBottom: "40px",
         textAlign: "center",
      },
   },
   forgot: {
      display: "flex",
      justifyContent: "end",
      fontWeight: 600,
      fontSize: "16px",
      color: "#3a3f63",
   },
   eye_icon: {
      color: "#858ebd",
   },
   divider: {
      marginTop: "20px",
      display: "flex",
      alignItems: "center",
   },
   forward: {
      display: "flex",
      justifyContent: "center",
      marginTop: "21px",
      fontSize: "18px",
      color: "#3a3f63",
      fontWeight: 400,

      [theme.breakpoints.down("sm")]: {
         display: "block",
         textAlign: "center",
         marginTop: 0,
      },
   },
   anchor_link: {
      color: "#3a3f63 !important",
      paddingLeft: "0.5em",
   },
   form_login: {
      width: 700,
      height: "auto",
      borderRadius: "20px",
      padding: "35px 70px",
      backgroundColor: "white",

      [theme.breakpoints.down("md")]: {
         width: "100%",
      },

      [theme.breakpoints.down("sm")]: {
         width: "100%",
         padding: "30px 38px",
      },
   },
   button_google_custom: {
      width: "100%",
      marginTop: 3,
      height: 65,
      borderRadius: 4,
      backgroundColor: "white",
      boxShadow: "0px 4px 16px 0px rgba(179, 192, 231, 0.32)",
      display: "flex",
      alignItems: "center",
      padding: "0px 10px",
      marginBottom: "21px",
      cursor: "pointer",

      [theme.breakpoints.down("sm")]: {
         borderRadius: "15px",
         padding: "18px 21px",
         color: "#3A3F63",
         fontWeight: 500,
         marginTop: "17px",
      },
   },
   button_forgot: {
      display: "none",
      color: "#3A3F63",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      marginTop: "16px",
      cursor: "pointer",

      [theme.breakpoints.down("sm")]: {
         display: "block",
         textAlign: "right",
      },
   },
   text: {
      [theme.breakpoints.down("sm")]: {
         display: "block",
      },
   },
}));
