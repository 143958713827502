import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
   sidebar_container: {
      // backgroundColor: "white",
      height: "calc(100vh - 0rem)",
      width: "100%",
      paddingTop: "2rem",
   },
}));
