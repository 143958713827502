import { Box, CircularProgress, Stack } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { PersistGate } from "redux-persist/integration/react";

import {
   AccountPath,
   AuthPrefix,
   DashboardURLPath,
   HomeURLPath,
   LoginPath,
   OpportunityPath,
   WalletURL,
} from "./helpers/routes";
import { Home } from "./modules/Home";
import Account from "./pages/Account";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Opportunities from "./pages/Opportunities";
import Wallet from "./pages/Wallet";
import { persistor, store } from "./store";

function App() {
   return (
      <Provider store={store}>
         <PersistGate loading={<CircularProgress />} persistor={persistor}>
            <Stack height={"100vh"}>
               <Stack direction={{ xs: "column", sm: "row" }} flexGrow={1}>
                  <Box flexGrow={1}>
                     <Routes>
                        <Route path={HomeURLPath} element={<Home />} />
                        <Route
                           path={DashboardURLPath}
                           element={<Dashboard />}
                        />

                        <Route path={WalletURL} element={<Wallet />} />
                        <Route path={AccountPath} element={<Account />} />
                        <Route
                           path={OpportunityPath}
                           element={<Opportunities />}
                        />
                        <Route path={AuthPrefix}>
                           <Route path={LoginPath} element={<Login />} />
                        </Route>
                     </Routes>
                  </Box>
               </Stack>
            </Stack>
         </PersistGate>
      </Provider>
   );
}

export default App;
