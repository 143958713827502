import { Avatar, Box, Grid, Typography } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { drawerWidth } from "../DashboardSidebar";

import { useStyles } from "./styles";

const AppBar = styled(MuiAppBar, {
   shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
   zIndex: theme.zIndex.drawer + 1,
   transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   }),
}));

const Header = ({ toggle }) => {
   const classes = useStyles();
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const handleLogout = () => {
      dispatch({ type: "LOG_OUT_USER" });
      navigate("/auth/login");
   };

   return (
      <AppBar sx={{ boxShadow: "none" }}>
         <Grid item container className={classes.header_container}>
            <Grid
               sx={{
                  fontSize: "48px",
                  color: "white",
                  fontWeight: 900,
                  pl: 4,
               }}
               onClick={() => {
                  toggle();
               }}
            >
               <Typography
                  fontWeight={700}
                  fontSize={40}
                  fontFamily={"'Rubik'"}
               >
                  VIM
               </Typography>
            </Grid>
            <Grid className={classes.name_container}>
               <Grid style={{ marginRight: "5px" }}>
                  <Typography
                     onClick={handleLogout}
                     style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                     Log Out
                  </Typography>
               </Grid>
               <Grid>
                  <Avatar alt="Avatar" className={classes.avatar} />
               </Grid>
            </Grid>
         </Grid>
         <Box className={classes.header_mobile}>
            <Typography
               style={{
                  marginRight: "5px",
                  cursor: "pointer",
                  textDecoration: "underlined",
               }}
               onClick={handleLogout}
            >
               Log Out
            </Typography>
            <Box
               onClick={() => {
                  toggle();
               }}
            >
               <Typography
                  fontWeight={700}
                  fontSize={32}
                  fontFamily={"'Rubik'"}
                  lineHeight={"20px"}
               >
                  VIM
               </Typography>
            </Box>
         </Box>
      </AppBar>
   );
};

export default Header;
