import { ArrowDropDown, List, WalletOutlined } from "@mui/icons-material";
import {
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   FormControl,
   FormHelperText,
   Grid,
   InputBase,
   InputLabel,
   MenuItem,
   Select,
   TextField,
   Typography,
   alpha,
   styled,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { callApi } from "../../api/api";
import DashboardLayout from "../../components/DashboardLayout";
import Expandable from "../../components/Expandable";
import Bitcoin from "../../components/WalletExapand/Bitcoin";
import Ethereum from "../../components/WalletExapand/Ethereum";
import WalletCard from "../../components/WalletExapand/WalletCard";
import { STYLING_PAGES } from "../../utils";

import { useStyles } from "./styles";

const Search = styled("div")(({ theme }) => ({
   position: "relative",
   borderRadius: theme.shape.borderRadius,
   zIndex: 1,
   backgroundColor: alpha(theme.palette.common.white, 0.15),
   "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
   },
   marginRight: theme.spacing(0),
   marginLeft: 0,
   width: "100%",
   [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0),
      width: "auto",
   },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
   padding: theme.spacing(0, 2),
   height: "100%",
   position: "absolute",
   pointerEvents: "none",
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
   color: "inherit",
   border: "1px solid #DBDBDB",
   borderRadius: "5px",
   "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      backgroundColor: "white",
   },
}));

const Wallet = () => {
   const classes = useStyles();
   const dispatch = useDispatch();
   const user = useSelector((state) => state.user);
   const [open, setOpen] = useState();
   const [wallet, setWallet] = useState();
   const [protocol, setProtocol] = useState();
   const [edit, setEdit] = useState();
   const [nameUpdates, setNameUpdates] = useState({});
   const [error, setError] = useState();
   const [requesting, setRequesting] = useState();
   const [listNames, setListNames] = useState(
      user.all_wallet_lists.map((i) => i.list_name)
   );
   const [newListName, setNewListName] = useState();
   const [newListNameCustom, setNewListNameCustom] = useState();

   const settings = {
      className: "slider variable-width",
      dots: false,
      arrows: false,
      infinite: false,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
   };

   const handleNameUpdate = (index, val) => {
      setNameUpdates({
         ...nameUpdates,
         [index]: val,
      });
   };

   const handleUpdateName = () => {
      setRequesting(true);

      callApi({
         url: `/api/v1/wallets/${edit}`,
         method: "PUT",
         data: {
            wallet: {
               name: nameUpdates[edit],
            },
         },
      })
         .then((res) => {
            if (res.errors) {
               setError("Error updating, try again.");
            } else {
               dispatch({ type: "SET_USER", payload: res });
               setEdit();
            }
         })
         .finally(() => setRequesting());
   };

   const handleClick = (value) => {
      if (value === open) setOpen(null);
      else setOpen(value);
   };

   const handleAdd = () => {
      setRequesting(true);

      const data = {
         wallet: {
            user_id: user.id,
            address: wallet,
            name:
               newListName === "Add List +" ? newListNameCustom : newListName,
            protocol,
         },
      };

      if (newListName === "Add List +") {
         data.wallet_list = {
            name: newListNameCustom,
            protocol,
         };
      } else {
         if (listNames.includes(newListName)) {
            data.wallet.wallet_list_id = user.all_wallet_lists.filter(
               (i) => i.list_name === newListName
            )[0].id;
         } else {
            data.wallet_list = {
               name: newListName,
               protocol,
            };
         }
      }

      callApi({
         url: "/api/v1/wallets/",
         method: "POST",
         data,
      })
         .then((res) => {
            dispatch({ type: "SET_USER", payload: res });
         })
         .finally(() => setRequesting());
   };

   const getEthTokenNum = (wallets) => {
      let val = 0;

      if (!wallets || wallets.length === 0) {
         return val;
      }

      val += 1;

      val += wallets.erc ? wallets.erc.length : 0;

      return val;
   };

   return (
      <DashboardLayout>
         <Box className={classes.view_desktop}>
            <Grid item container spacing={2}>
               <Grid item xs={12} sm={12} md={3} lg={2}>
                  <Grid>
                     <Card className={classes.card_container}>
                        <CardContent>
                           <Typography
                              sx={{
                                 fontSize: 16,
                                 fontWeight: 900,
                                 color: "white",
                              }}
                              gutterBottom
                           >
                              ADD WALLET
                           </Typography>
                           <FormControl
                              fullWidth
                              sx={{ mt: "6px" }}
                              size="small"
                           >
                              <InputLabel id="demo-simple-select-label">
                                 Protocol
                              </InputLabel>
                              <Select
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 value={protocol}
                                 label="Protocol"
                                 onChange={(e) => {
                                    setProtocol(e.target.value);

                                    if (user.all_wallet_lists.length > 0) {
                                       setListNames(
                                          user.all_wallet_lists
                                             .filter(
                                                (i) =>
                                                   i.protocol === e.target.value
                                             )
                                             .map((i) => i.list_name)
                                       );
                                    }
                                 }}
                                 IconComponent={ArrowDropDown}
                                 sx={{
                                    "& .MuiInputBase-input": {
                                       background: "white",
                                    },
                                 }}
                              >
                                 {["Ethereum", "Bitcoin"].map((i) => {
                                    return (
                                       <MenuItem value={i.toLowerCase()}>
                                          {i}
                                       </MenuItem>
                                    );
                                 })}
                              </Select>
                           </FormControl>

                           {protocol && (
                              <FormControl
                                 fullWidth
                                 sx={{ mt: "6px" }}
                                 size="small"
                              >
                                 <InputLabel id="demo-simple-select-label">
                                    List
                                 </InputLabel>
                                 <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={newListName}
                                    label="Protocol"
                                    onChange={(e) =>
                                       setNewListName(e.target.value)
                                    }
                                    IconComponent={ArrowDropDown}
                                    sx={{
                                       "& .MuiInputBase-input": {
                                          background: "white",
                                       },
                                    }}
                                 >
                                    {listNames.map((i) => {
                                       return (
                                          <MenuItem value={i}>{i}</MenuItem>
                                       );
                                    })}
                                    <MenuItem value={"Add List +"}>
                                       Add List +
                                    </MenuItem>
                                 </Select>
                              </FormControl>
                           )}

                           {newListName === "Add List +" && (
                              <>
                                 <Typography
                                    variant="body2"
                                    sx={{ mt: "10px", color: "white" }}
                                 >
                                    List Name
                                 </Typography>
                                 <Grid sx={{ mt: "6px" }}>
                                    <TextField
                                       InputProps={{
                                          style: {
                                             background: "white",
                                             height: "2rem",
                                          },
                                       }}
                                       placeholder="Ex. My List"
                                       fullWidth
                                       id="outlined-basic"
                                       variant="outlined"
                                       value={newListNameCustom}
                                       onChange={(e) =>
                                          setNewListNameCustom(e.target.value)
                                       }
                                    />
                                 </Grid>
                              </>
                           )}

                           <Grid sx={{ mt: "16px" }}>
                              <Search>
                                 <SearchIconWrapper>
                                    <WalletOutlined style={{ zIndex: 11 }} />
                                 </SearchIconWrapper>
                                 <StyledInputBase
                                    placeholder="Address"
                                    inputProps={{ "aria-label": "search" }}
                                    defaultValue={wallet}
                                    onChange={(e) => setWallet(e.target.value)}
                                    sx={{ width: "100%" }}
                                 />
                              </Search>
                              {wallet && protocol && (
                                 <FormHelperText
                                    style={{
                                       margin: "10px",
                                       fontStyle: "italic",
                                       color: "#fff",
                                    }}
                                 >
                                    {protocol === "bitcoin" ? "BTC" : "ETH"}
                                 </FormHelperText>
                              )}
                           </Grid>
                        </CardContent>
                        <CardActions className={classes.add_btn_container}>
                           <Button
                              variant="contained"
                              size="small"
                              className={classes.add_btn}
                              disabled={!wallet || requesting}
                              onClick={handleAdd}
                           >
                              Add
                           </Button>
                        </CardActions>
                     </Card>
                  </Grid>
               </Grid>
               <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  lg={10}
                  className={classes.accordion_container}
               >
                  {user.all_wallet_lists.map((i) => {
                     return (
                        <Grid>
                           <Expandable
                              Component={
                                 i.protocol === "bitcoin" ? Bitcoin : Ethereum
                              }
                              open={open}
                              handleClick={handleClick}
                              type={STYLING_PAGES.ETHEREUM_PAGE}
                              title={i.list_name}
                              tokens={`${
                                 i.protocol === "bitcoin"
                                    ? "1"
                                    : getEthTokenNum(i.wallets)
                              } TOKENS `}
                              wallets={`# WALLETS ${i.wallets.length}`}
                              usdBalance={`USD BALANCE ${i.usd_balance.toLocaleString(
                                 "en-US",
                                 {
                                    style: "currency",
                                    currency: "USD",
                                 }
                              )}`}
                              protocol={i.protocol}
                           />
                        </Grid>
                     );
                  })}
               </Grid>
            </Grid>
         </Box>

         <Box className={classes.view_mobile}>
            <Typography
               className={classes.staking_title}
               sx={{ fontSize: "24px !important", mb: "10px !important" }}
            >
               WALLETS
            </Typography>
            <Typography
               className={classes.staking_text}
               sx={{ textAlign: "left !important", mb: "16px" }}
            >
               View balances of your current investments here.
            </Typography>

            <Box>
               {user.all_wallet_lists.map((i, index) => {
                  return (
                     <Box key={index} sx={{ mb: 4 }}>
                        <Box className={classes.header_section}>
                           <Typography className={classes.title_section}>
                              {i.list_name}
                           </Typography>
                        </Box>

                        <Box sx={{ ovflow: "hidden" }}>
                           {i.wallets && i.wallets.length > 0 && (
                              <Slider {...settings}>
                                 {i.wallets.map((slide, s) => (
                                    <WalletCard
                                       key={s}
                                       walletInfo={{
                                          protocol:
                                             i.protocol === "bitcoin"
                                                ? "BTC"
                                                : "ETH",
                                          bal: {
                                             usd: slide.usd_balance.usd.toLocaleString(
                                                "en-US",
                                                {
                                                   style: "currency",
                                                   currency: "USD",
                                                }
                                             ),
                                             eth: 0,
                                          },
                                       }}
                                       item={slide}
                                       edit={edit}
                                       requesting={requesting}
                                       nameUpdates={nameUpdates}
                                       onNameUpdate={handleNameUpdate}
                                       onUpdateName={handleUpdateName}
                                       onEdit={setEdit}
                                    />
                                 ))}
                              </Slider>
                           )}
                        </Box>
                     </Box>
                  );
               })}
            </Box>

            <Box>
               <Typography
                  sx={{
                     fontSize: 16,
                     fontWeight: 900,
                     color: "white",
                  }}
                  gutterBottom
                  className={classes.staking_title}
               >
                  ADD WALLET
               </Typography>

               <Grid container spacing={2}>
                  <Grid item xs={6}>
                     <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                           Protocol
                        </InputLabel>
                        <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={protocol}
                           label="Protocol"
                           onChange={(e) => {
                              setProtocol(e.target.value);

                              if (user.all_wallet_lists.length > 0) {
                                 setListNames(
                                    user.all_wallet_lists
                                       .filter(
                                          (i) => i.protocol === e.target.value
                                       )
                                       .map((i) => i.list_name)
                                 );
                              }
                           }}
                           IconComponent={ArrowDropDown}
                           sx={{
                              "& .MuiInputBase-input": {
                                 background: "white",
                              },
                              "& .MuiSelect-select": {
                                 border: "none !important",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                 border: "none !important",
                              },
                           }}
                           className={classes.select_list}
                        >
                           {["Ethereum", "Bitcoin"].map((i) => {
                              return (
                                 <MenuItem value={i.toLowerCase()}>
                                    {i}
                                 </MenuItem>
                              );
                           })}
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                     <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                           List
                        </InputLabel>
                        <Select
                           disabled={!protocol}
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={newListName}
                           label="Protocol"
                           onChange={(e) => setNewListName(e.target.value)}
                           IconComponent={ArrowDropDown}
                           sx={{
                              "& .MuiInputBase-input": {
                                 background: "white",
                              },
                           }}
                        >
                           {listNames.map((i) => {
                              return <MenuItem value={i}>{i}</MenuItem>;
                           })}
                           <MenuItem value={"Add List +"}>Add List +</MenuItem>
                        </Select>
                     </FormControl>
                  </Grid>

                  {newListName === "Add List +" && (
                     <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                           <Search
                              sx={{
                                 border: "none",

                                 "& .MuiInputBase-root": {
                                    border: "none",
                                 },

                                 "& .MuiInputBase-input": {
                                    borderRadius: "4px",
                                 },
                              }}
                           >
                              <SearchIconWrapper>
                                 <List style={{ zIndex: 11 }} />
                              </SearchIconWrapper>
                              <StyledInputBase
                                 placeholder="List Name"
                                 inputProps={{ "aria-label": "search" }}
                                 defaultValue={newListNameCustom}
                                 onChange={(e) =>
                                    setNewListNameCustom(e.target.value)
                                 }
                                 sx={{ width: "100%" }}
                              />
                           </Search>
                        </FormControl>
                     </Grid>
                  )}
                  <Grid item xs={6}>
                     <Box>
                        <Search
                           sx={{
                              border: "none",

                              "& .MuiInputBase-root": {
                                 border: "none",
                              },

                              "& .MuiInputBase-input": {
                                 borderRadius: "4px",
                              },
                           }}
                        >
                           <SearchIconWrapper>
                              <WalletOutlined style={{ zIndex: 11 }} />
                           </SearchIconWrapper>
                           <StyledInputBase
                              placeholder="Address"
                              inputProps={{ "aria-label": "search" }}
                              defaultValue={wallet}
                              onChange={(e) => setWallet(e.target.value)}
                              sx={{ width: "100%" }}
                           />
                        </Search>
                        {wallet && protocol && (
                           <FormHelperText
                              style={{
                                 margin: "10px",
                                 fontStyle: "italic",
                                 color: "#fff",
                              }}
                           >
                              {protocol === "bitcoin" ? "BTC" : "ETH"}
                           </FormHelperText>
                        )}
                     </Box>
                  </Grid>
                  <Grid item xs={12}>
                     <Box sx={{ textAlign: "right" }}>
                        <Button
                           variant="contained"
                           size="small"
                           className={classes.add_btn}
                           disabled={!wallet || requesting}
                           onClick={handleAdd}
                        >
                           Add
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Box>
      </DashboardLayout>
   );
};

export default Wallet;
