import { Box, Collapse, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { useDispatch } from "react-redux";

import { callApi } from "../../api/api";
import BtcLogo from "../../assets/images/bitcoin-symbol.png";
import menuImage from "../../assets/images/dashboard/menu.svg";
import EthLogo from "../../assets/images/eth-diamond.png";
import PopupEditNode from "../PopupEditNode";

import { useStyles } from "./styles";

const isPageExpand = (status, type) => status === type;

function Expandable({
   Component,
   open,
   handleClick,
   type,
   title,
   nodetitle,
   balance,
   income,
   avg,
   tokens,
   wallets,
   usdBalance,
   tbalance,
   name,
   currentEthValue,
   nodes,
   nodeId,
   protocol = null,
}) {
   const dispatch = useDispatch();
   const classes = useStyles();
   const [modalOpen, setModalOpen] = useState(false);
   const handleClose = () => setModalOpen(false);
   const [nameUpdates, setNameUpdates] = useState({});
   const [newTitle, setNewTitle] = useState(title);
   const [error, setError] = useState();
   const [requesting, setRequesting] = useState();

   const handleModal = () => {
      setModalOpen(true);
   };

   const handleNameUpdate = (index, val) => {
      setNameUpdates({
         ...nameUpdates,
         [index]: val,
      });
   };

   const handleUpdateList = () => {
      setRequesting(true);

      const nodes_attributes = [];

      Object.keys(nameUpdates).forEach((id) => {
         nodes_attributes.push({ id, name: nameUpdates[id] });
      });

      callApi({
         url: `/api/v1/node_lists/${nodeId}`,
         method: "PUT",
         data: {
            node_list: {
               name: newTitle,
               nodes_attributes,
            },
         },
      })
         .then((res) => {
            if (res.errors) {
               setError("Error updating, try again.");
            } else {
               dispatch({ type: "SET_USER", payload: res });
               setModalOpen();
            }
         })
         .finally(() => setRequesting());
   };

   const handleDeleteList = () => {
      const userConfirmed = window.confirm(
         "Are you sure you want to delete this list?"
      );

      if (userConfirmed) {
         setRequesting(true);

         callApi({
            url: `/api/v1/node_lists/${nodeId}`,
            method: "DELETE",
         })
            .then((res) => dispatch({ type: "SET_USER", payload: res }))
            .finally(() => setRequesting());
      }
   };

   const handleDeleteNode = (index) => {
      const userConfirmed = window.confirm(
         "Are you sure you want to delete this node?"
      );

      if (userConfirmed) {
         setRequesting(true);

         callApi({
            url: `/api/v1/nodes/${index}`,
            method: "DELETE",
         })
            .then((res) => dispatch({ type: "SET_USER", payload: res }))
            .finally(() => setRequesting());
      }
   };

   return (
      <>
         <Grid item continer className={classes.card_container}>
            <Grid className={classes.title_container}>
               <Grid
                  item
                  xs={5}
                  sm={12}
                  md={3}
                  lg={3}
                  className={classes.header_container}
                  onClick={() => handleClick(type)}
               >
                  <Grid className={classes.icon_box_container}>
                     <Box className={classes.icon_box}>
                        <IconButton
                           onClick={() => handleClick(type)}
                           color="primary"
                           aria-label={title}
                           className={classes.icon_btn_container}
                        >
                           {isPageExpand(open, type) ? (
                              <MdExpandLess className={classes.icon_ui} />
                           ) : (
                              <MdExpandMore className={classes.icon_ui} />
                           )}
                        </IconButton>
                     </Box>
                  </Grid>
                  <Typography className={classes.title}>
                     {protocol ? (
                        <div style={{ display: "flex" }}>
                           <img
                              src={protocol === "ethereum" ? EthLogo : BtcLogo}
                              height={24}
                           />
                           <span style={{ marginLeft: "10px" }}>{title}</span>
                        </div>
                     ) : (
                        title
                     )}
                  </Typography>
               </Grid>
               <Grid item xs={7} sm={12} md={9} lg={9}>
                  <Grid className={classes.sub_title_container}>
                     <Typography
                        sx={{
                           fontSize: "16px",
                           color: "#3A3F63",
                           fontWeight: 900,
                        }}
                     >
                        {nodetitle}
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: "16px",
                           color: "#3A3F63",
                           fontWeight: 900,
                        }}
                     >
                        {balance}
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: "16px",
                           color: "#3A3F63",
                           fontWeight: 900,
                        }}
                     >
                        {income}
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: "16px",
                           color: "#3A3F63",
                           fontWeight: 900,
                        }}
                     >
                        {avg}
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: "small",
                           color: "rgba(58, 63, 99, 1)",
                           fontWeight: 900,
                           maxWidth: { xs: "65px", sm: "auto" },
                        }}
                     >
                        {tokens}
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: "small",
                           color: "rgba(58, 63, 99, 1)",
                           fontWeight: 900,
                           display: { xs: "none", sm: "block" },
                        }}
                     >
                        {wallets}
                     </Typography>
                     <Typography
                        sx={{
                           fontSize: "small",
                           color: "rgba(58, 63, 99, 1)",
                           fontWeight: 900,
                           maxWidth: { xs: "100px", sm: "auto" },
                        }}
                     >
                        {usdBalance}
                     </Typography>

                     <Grid sx={{ cursor: "pointer" }} onClick={handleModal}>
                        <img src={menuImage} alt="menuImage" />
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
            <Collapse
               in={isPageExpand(open, type)}
               timeout="auto"
               unmountOnExit
            >
               <Component
                  title={title}
                  name={name}
                  currentEthValue={currentEthValue}
               />
            </Collapse>
         </Grid>

         {nodes && (
            <PopupEditNode
               modalOpen={modalOpen}
               onClose={handleClose}
               onOpen={setModalOpen}
               title={title}
               newTitle={newTitle}
               onNewTitle={setNewTitle}
               nodes={nodes}
               nameUpdates={nameUpdates}
               onUpdateName={handleNameUpdate}
               onDeleteNode={handleDeleteNode}
               onDeleteList={handleDeleteList}
               onUpdateList={handleUpdateList}
            />
         )}
      </>
   );
}

export default React.memo(Expandable);
