import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
   card_container: {
      backgroundColor: `${theme.palette.background.default}`,
      borderRadius: "8px",
      marginBottom: "18px",
      position: "relative",
      boxShadow: "0px 4px 16px 0px rgba(179, 192, 231, 0.32)",
   },
   title_container: {
      display: "flex",
      alignItems: "center",
      padding: "0.5rem",
      height: "64px",
   },
   header_container: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
   },
   icon_box_container: {
      paddingLeft: "20px",
   },
   icon_box: {
      boxShadow: "0px 4px 8px 0px rgba(179, 192, 231, 0.32)",
      display: "flex",
      alignItems: "center",
   },
   icon_btn_container: {
      padding: 0,
   },
   icon_ui: {
      height: "18px",
      width: "18px",
      color: "rgba(133, 142, 189, 1)",
   },

   title: {
      fontSize: "16px",
      fontWeight: 900,
      color: "#3a3f63",
      paddingLeft: "14px",
   },
   sub_title_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
   },
   text_details: {
      color: "#9ca3c9",
   },
   arrow_icon: {
      color: "#858ebd",
   },
   text_title: {
      display: "flex",
      justifyContent: "center",
      marginTop: "-27px",
   },
   name_box_container: {
      paddingLeft: "20px",
   },
   delete_icon: {
      color: "#9ca3c9",
      height: "24px",
      width: "24px",
   },
   add_btn: {
      background: "#4d76ff",
      borderRadius: "10px",
      width: "37px",
      height: "24px",
      textTransform: "capitalize",
   },
}));
