import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
   etherum_card_conatiner: {
      padding: "2rem 1rem 1rem",
   },
   etherum_card: {
      position: "relative",
      borderRadius: "14px",
      width: "12rem",
      boxShadow: "2px 4px 8px 0px rgb(179 192 231 / 32%) !important",

      [theme.breakpoints.down("sm")]: {
         display: "inline-flex",
         marginRight: "16px",
         width: "9rem",
         height: "100%",
      },
   },
   nick_name: {
      fontSize: "16px",
      fontWeight: 900,
      textTransform: "uppercase",
      color: "#3A3F63",

      [theme.breakpoints.down("sm")]: {
         fontSize: "12px",
      },
   },
   edit_icon: {
      paddingLeft: "5px",
   },
   balance: {
      fontSize: "14px",
      color: "#979797",
      paddingTop: "10px",

      [theme.breakpoints.down("sm")]: {
         fontSize: "10px",
         paddingTop: "0px",
      },
   },
   total_coin: {
      color: "#3A3F63",
      fontWeight: 400,

      [theme.breakpoints.down("sm")]: {
         fontSize: "13px",
      },
   },
   up_down: {
      color: "#00D0B5",
      fontSize: "14px",
      paddingLeft: "10px",
   },
   deposite: {
      fontSize: "12px",
      color: "#979797",
      paddingTop: "10px",

      [theme.breakpoints.down("sm")]: {
         paddingTop: "4px",
      },
   },
   info: {
      fontSize: "12px",
      color: "#3A3F63",
   },
   view_qr: {
      fontSize: "12px",
      color: "#979797",
      paddingTop: "10px",
      textDecoration: "underline",

      [theme.breakpoints.down("sm")]: {
         fontSize: "10px",
         paddingTop: "4px",
      },
   },
   avtar: {
      height: "44px",
      width: "44px",
      background: "#D9D9D9",
      borderRadius: "50%",
      position: "absolute",
      transform: "translate(170px, -225px)",
   },
   card_container: {
      borderRadius: "16px",
      minHeight: "210px",
      boxShadow: "2px 4px 8px 0px rgb(179 192 231 / 32%) !important",
      width: "12rem",
   },
   add_btn_container: {
      display: "flex",
      justifyContent: "end",
   },
   add_btn: {
      background: "#4d76ff",
      borderRadius: "10px",
      width: "73px",
      height: "31px",
      textTransform: "capitalize",
   },
}));
