import React, { useEffect } from "react";

const GoogleLoginButton = ({ clientId, onSuccess, onFailure }) => {
   useEffect(() => {
      window.google.accounts.id.initialize({
         client_id: clientId,
         callback: handleCredentialResponse,
      });

      window.google.accounts.id.renderButton(
         document.getElementById("gbutton"),
         {
            theme: "outline",
            size: "large",
            shape: "rounded",
         }
      );
   }, []);

   const handleCredentialResponse = (response) => {
      if (response.credential) {
         onSuccess(response);
      } else {
         onFailure("Failed to retrieve credentials");
      }
   };

   return (
      <div style={{ width: "100%", margin: "20px auto" }} id="gbutton">
         <div></div>
      </div>
   );
};

export default GoogleLoginButton;
