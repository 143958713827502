import {
   CircularProgress,
   Dialog,
   DialogContent,
   Divider,
   FormControl,
   Grid,
   OutlinedInput,
   Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { apiLoginWithGoogle, apiSignIn, apiSignUp } from "../../api/user";
import faq_logo from "../../assets/images/FAQ.svg";
import vim_logo from "../../assets/images/vim_logo_blue.svg";
import GoogleLoginButton from "../../components/common/GoogleLoginButton";

import { useStyles } from "./styles";

const SCREENS = {
   LOGIN: 1,
   SIGN_UP: 2,
};

function Login() {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const classes = useStyles();
   const [screen, setScreen] = useState(SCREENS.LOGIN);
   const [requesting, setRequesting] = useState(false);
   const [requestingGoogle, setRequestingGoogle] = useState();
   const [email, setEmail] = useState();
   const [password, setPassword] = useState();
   const [errors, setErrors] = useState();

   const handleError = (errors) => {
      setErrors(errors);
      const timeout = setTimeout(() => {
         setErrors([]);
      }, 5000);

      return () => {
         clearTimeout(timeout);
      };
   };

   const navToDashboard = (res) => {
      dispatch({ type: "SET_USER", payload: res });
      navigate("/dashboard");
   };

   const signIn = () => {
      setRequesting(true);

      apiSignIn({
         user: {
            email,
            password,
         },
      }).then((res) => {
         if (res.errors) {
            handleError(res.errors.join(", "));
         } else {
            navToDashboard(res);
         }

         setRequesting(false);
      });
   };

   const signUp = () => {
      setRequesting(true);

      apiSignUp({ user: { email, password } }).then((res) => {
         if (res.errors) {
            handleError(res.errors.join(", "));
         } else {
            navToDashboard(res);
         }

         setRequesting(false);
      });
   };

   const onSuccess = (res) => {
      setRequestingGoogle(true);

      const data = {
         jwt: res.credential,
      };

      apiLoginWithGoogle(data).then((res) => {
         setRequestingGoogle();
         navToDashboard(res);
      });
   };

   const onFailure = (err) => {
      console.error("err", err);
   };

   return (
      <>
         <Grid className={classes.logo_container}>
            <Grid className={classes.logo}>
               <img src={vim_logo} alt="log" className={classes.logo_img} />
            </Grid>
            <Grid>
               <img
                  src={faq_logo}
                  alt="faq_logo"
                  className={classes.faq_logo}
               />
            </Grid>
         </Grid>
         <div className={classes.view_logo}>
            <img src={vim_logo} alt="log" className={classes.logo_img_mobile} />
         </div>
         <div className={classes.mainbox}>
            <Grid className={classes.form_grid}>
               <Box className={classes.form_login}>
                  <Typography
                     variant="h4"
                     gutterBottom
                     className={classes.heading}
                  >
                     {screen === SCREENS.LOGIN ? "WELCOME BACK!" : "SIGN UP"}
                  </Typography>

                  <Typography
                     display="block"
                     sx={{
                        marginTop: 3,
                        color: "rgba(58, 63, 99, 1)",
                        lineHeight: "24px",
                        mb: "2px",
                     }}
                  >
                     Email address
                  </Typography>
                  <TextField
                     fullWidth
                     name="email"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                     id="fullWidth"
                  />
                  <Typography
                     display="block"
                     sx={{
                        marginTop: 3,
                        color: "rgba(58, 63, 99, 1)",
                        mb: "2px",
                     }}
                  >
                     Password
                  </Typography>

                  <FormControl sx={{ width: "100%" }} variant="outlined">
                     <OutlinedInput
                        id="outlined-adornment-password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        type="password"
                     />
                  </FormControl>

                  {screen === SCREENS.LOGIN ? (
                     <Button
                        variant="contained"
                        disabled={!email || !password || requesting}
                        onClick={signIn}
                        fullWidth
                        sx={{
                           marginTop: 3,
                           backgroundColor: "#4c70ff",
                           padding: 2,
                           borderRadius: 4,
                           boxShadow: "0px 4px 16px 0px rgba(179, 192, 231, 1)",
                           textTransform: "initial",
                           fontSize: "18px",
                        }}
                     >
                        {requesting ? <CircularProgress /> : "Sign in"}
                     </Button>
                  ) : (
                     <Button
                        variant="contained"
                        disabled={!email || !password || requesting}
                        onClick={signUp}
                        fullWidth
                        sx={{
                           marginTop: 3,
                           backgroundColor: "#4c70ff",
                           padding: 2,
                           borderRadius: 4,
                           boxShadow: "0px 4px 16px 0px rgba(179, 192, 231, 1)",
                           textTransform: "initial",
                           fontSize: "18px",
                        }}
                     >
                        Sign up
                     </Button>
                  )}
                  <Typography className={classes.button_forgot}>
                     Forgot Password?
                  </Typography>
                  <Grid className={classes.divider}>
                     <Grid>
                        <Typography sx={{ color: "#3a3f63" }}>OR</Typography>
                     </Grid>
                     <Grid sx={{ width: "100%", marginLeft: 2 }}>
                        <Divider />
                     </Grid>
                  </Grid>

                  <GoogleLoginButton
                     clientId={process.env.REACT_APP_GOOGLE_ID}
                     onSuccess={onSuccess}
                     onFailure={onFailure}
                  />

                  {errors && (
                     <Typography style={{ margin: "10px", color: "#ff0000" }}>
                        {errors}
                     </Typography>
                  )}
               </Box>

               {screen === SCREENS.LOGIN ? (
                  <div className={classes.forward}>
                     <p className={classes.text}>Don't have an account?</p>
                     <Link
                        className={classes.anchor_link}
                        onClick={() => setScreen(SCREENS.SIGN_UP)}
                     >
                        Create one now
                     </Link>
                  </div>
               ) : (
                  <div className={classes.forward}>
                     Have an account?
                     <Link
                        className={classes.anchor_link}
                        onClick={() => setScreen(SCREENS.LOGIN)}
                     >
                        Login
                     </Link>
                  </div>
               )}
            </Grid>
         </div>

         <Dialog
            open={requestingGoogle || requesting}
            onClose={() => {}}
            fullWidth
         >
            <DialogContent style={{ textAlign: "center", padding: "20px" }}>
               <CircularProgress />
               <Typography variant="h6" style={{ marginTop: "20px" }}>
                  Reading the blockchain...
               </Typography>
            </DialogContent>
         </Dialog>
      </>
   );
}

export default Login;
