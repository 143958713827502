import { Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { callApi } from "../../../api/api";
import WalletCard from "../WalletCard";

import { useStyles } from "./styles";

const Bitcoin = () => {
   const classes = useStyles();
   const user = useSelector((state) => state.user);
   const dispatch = useDispatch();
   const [edit, setEdit] = useState();
   const [nameUpdates, setNameUpdates] = useState({});
   const [error, setError] = useState();
   const [requesting, setRequesting] = useState();

   const handleNameUpdate = (index, val) => {
      setNameUpdates({
         ...nameUpdates,
         [index]: val,
      });
   };

   const handleUpdateName = () => {
      setRequesting(true);

      callApi({
         url: `/api/v1/wallets/${edit}`,
         method: "PUT",
         data: {
            wallet: {
               name: nameUpdates[edit],
            },
         },
      })
         .then((res) => {
            if (res.errors) {
               setError("Error updating, try again.");
            } else {
               dispatch({ type: "SET_USER", payload: res });
               setEdit();
            }
         })
         .finally(() => setRequesting());
   };

   return (
      <>
         <Divider />
         <Grid container spacing={3} className={classes.etherum_card_conatiner}>
            {user.all_wallet_lists
               .filter((i) => i.protocol == "bitcoin")
               .map((i, index) => {
                  return i.wallets.map((item) => {
                     return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                           <WalletCard
                              item={item}
                              edit={edit}
                              requesting={requesting}
                              nameUpdates={nameUpdates}
                              onNameUpdate={handleNameUpdate}
                              onUpdateName={handleUpdateName}
                              onEdit={setEdit}
                           />
                        </Grid>
                     );
                  });
               })}
         </Grid>
      </>
   );
};

export default Bitcoin;
