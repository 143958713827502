import { Grid, Stack, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { useLocation, useMatch, useNavigate } from "react-router";

import { icons } from "../../utils";

import { useStyles } from "./styles";

export const drawerWidth = 250;

const openedMixin = (theme) => ({
   width: drawerWidth,
   transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
   }),
   overflowX: "hidden",
});

const closedMixin = (theme) => ({
   transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   overflowX: "hidden",
   width: `calc(${theme.spacing(7)} + 1px)`,
   [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
   },
});

const Drawer = styled(MuiDrawer, {
   shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
   width: drawerWidth,
   flexShrink: 0,
   whiteSpace: "nowrap",
   boxSizing: "border-box",
   ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
   }),
   ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
   }),
}));

const DashboardSidebar = ({ setStatus, handleDrawerToggle, status }) => {
   const classes = useStyles();
   const location = useLocation();
   const navigate = useNavigate();
   const container =
      window !== undefined ? () => window.document.body : undefined;
   const [activePage, setActivePage] = React.useState(
      location.pathname || "/dashboard"
   );

   const drawer = (
      <>
         <Toolbar />
         <Grid
            container
            className={classes.sidebar_container}
            sx={{
               backgroundColor: status ? "rgba(34, 38, 83, 1)" : "#f5f5fa",
            }}
            onClick={() => {
               handleDrawerToggle(true);
            }}
         >
            {icons.map((item, index) => {
               return (
                  <Grid
                     key={index}
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: "12px",
                     }}
                     container
                  >
                     <Stack
                        sx={{
                           backgroundColor:
                              activePage === item.path
                                 ? "rgba(76, 113, 255, 1)"
                                 : "transparent",
                           width: status ? "100%" : "auto",
                           mx: 1,
                           borderRadius: "12px",
                           cursor: "pointer",
                        }}
                        display="flex"
                        justifyContent="left"
                        direction="row"
                        alignContent={"center"}
                        alignItems={"center"}
                        onClick={() => {
                           navigate(item.path);
                        }}
                     >
                        <Grid
                           item
                           sx={{
                              backgroundColor:
                                 activePage === item.path
                                    ? "rgba(76, 113, 255, 1)"
                                    : "transparent",
                              borderRadius: "10px",
                              m: 1,
                              width: status ? "35px" : "24px",
                              height: status ? "35px" : "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                           }}
                        >
                           {activePage === item.path ? item.aIcon : item.icon}
                        </Grid>
                        {status && (
                           <Typography
                              sx={{
                                 color:
                                    activePage === item.path
                                       ? "white"
                                       : "rgba(179, 192, 231, 1)",
                              }}
                              fontSize={14}
                              fontWeight={500}
                           >
                              {item.title}
                           </Typography>
                        )}
                     </Stack>
                  </Grid>
               );
            })}
         </Grid>
      </>
   );

   return (
      <>
         <Drawer
            variant={"permanent"}
            onClose={handleDrawerToggle}
            sx={{
               display: { xs: "none", sm: "block" },
               "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
               },
            }}
         >
            {drawer}
         </Drawer>
         <MuiDrawer
            container={container}
            variant="temporary"
            open={status}
            onClose={handleDrawerToggle}
            anchor="left"
            sx={{
               display: { xs: "none", sm: "block" },

               "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
               },
            }}
         >
            {drawer}
         </MuiDrawer>
         <MuiDrawer
            container={container}
            variant="temporary"
            open={status}
            onClose={handleDrawerToggle}
            anchor="left"
            ModalProps={{
               keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
               display: { xs: "block", sm: "none" },
               "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
               },
            }}
         >
            {drawer}
         </MuiDrawer>
      </>
   );
};

export default DashboardSidebar;
